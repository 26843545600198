@import "../../mixins/global";

.StepPriceBadge {
  &__container {
    font: 12px $rxc-rayban-lato-font;
    margin-right: 8px;
    color: white !important;
    line-height: 1.5;
    background-color: #e80c00;
    border-radius: 32px;
    padding: 0 10px;
  }
}
