@import "../../mixins/global";

.StepCard {
  &__container {
    display: block;
    box-sizing: border-box;

    &_badge {
      position: absolute;
      top: 20px;
      right: 32px;
    }
  }

  &__card_disabled {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: 0;
    margin-bottom: 24px;
    overflow: visible;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;

    & .StepCard__layout {
      padding: 0px !important;
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    border-radius: 8px;
    border: none;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    padding: 0;
    margin-bottom: 24px;
    overflow: visible;
    cursor: pointer;
    text-align: left;

    &:focus {
      background-color: white;
      //box-shadow: 0 0 17px 0 rgb(0 0 0 / 12%);
      outline: none;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: -3px;
        left: -3px;
        width: 100%;
        height: 100%;
        border: 1.5px solid #1f1f24;
        border-radius: 8px;
        pointer-events: none;
        box-sizing: content-box;
      }
    }

    &:hover {
      background-color: white;
      //box-shadow: 0 0 17px 0 rgb(0 0 0 / 12%);
      outline: none;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border: 2px solid $rxc-black-rayban;
        border-radius: 8px;
        pointer-events: none;
        box-sizing: content-box;
      }
    }
  }

  &__layout {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden;

    @include rxc-mobile-only {
      padding: 0px;

      & .StepCard__layout__content__spacer {
        height: 1px;
        background-color: #d8d8d8 !important;
        z-index: 100;
        margin-top: 0px !important;
        margin-bottom: 8px !important;
      }

      & #notAvailable {
        margin: 0 16px 8px 0px;
        padding: 0;
        z-index: 100;
        font-family: $rxc-rayban-lato-font;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
      }
    }

    &__disabled {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #f6f6f6;
      border: 1px solid #d8d8d8;
      border-radius: 8px;

      &~.StepCard__layout__content.spacer {
        // padding: 16px 16px 16px 0px;
        padding: 16px;

        & .StepCard__layout__content__title {
          color: #767676 !important;
          opacity: 100%;
          z-index: 100;
        }

        & .StepCard__layout__content__description {
          z-index: 100;
          color: #767676 !important;
          opacity: 100%;
          z-index: 100;

          & ul {
            display: none;
          }
        }

        & .StepCard__layout__content__spacer {
          height: 1px;
          background-color: #d8d8d8 !important;
          z-index: 100;
        }

        & #notAvailable {
          margin: 0;
          padding: 0;
          z-index: 100;
          font-family: $rxc-rayban-lato-font;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
        }
      }

      &~.StepCard__layout__largeimgcontainer {
        z-index: 100;
        overflow: hidden;

        & .StepCard__layout__largeimgcontainer__largeimg {
          opacity: 30%;
        }
      }
    }

    &__mobileChips {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-flow: wrap;
        list-style: none;
        padding: 0px;
        margin: 16px 16px 0px;

        & li {
          height: 25px;
          width: fit-content;
          margin-bottom: 8px;
          margin-right: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 8px 4px 8px;
          background: #ffffff;
          border: 1px solid #1f1f24;
          border-radius: 100px;
          color: #1f1f24 !important;
          z-index: 3;
        }
      }

      @include rxc-desktop-only {
        display: none;
      }
    }

    &__imgcontainer {
      // box-sizing: border-box;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // flex: 0 0 64px;
      // border-radius: 8px 0 0 8px;
      // padding-right: 0;
      // padding-right: 16px;
      // align-self: flex-start;
      // margin-top: 16px;
      // max-width: 32px;
      // margin-left: 16px;
      // height: auto;

      max-width: 64px;
      height: auto;
      margin-top: 17.3px;
      margin-left: 16px;
      margin-right: 16px;

      @include rxc-mobile-only {
        display: none;
      }

      &.noImage {
        padding: 0 0 0 18px;
        flex: 0;
        display: none;
      }

      &__img {
        height: auto;
        max-width: 32px;
      }
    }

    &__largeimgcontainer {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 150px;
      max-width: 130px;
      max-height: 112px;

      &.fade-shadow::after {
        content: "";
        position: absolute;
        left: 0;
        width: 130px;
        //height: 170px;
        box-shadow: inset 10px -23px 15px -10px #fff;
        z-index: 2;

        @include rxc-mobile-only {
          //height: 145px;
        }
      }

      &.noImage {
        padding: 0 0 0 18px;
        flex: 0;
      }

      &__largeimg {
        align-self: center;
        max-width: 100%;
        width: fit-content;

        @include rxc-mobile-only {
          max-width: 100px;
        }
      }

      @include rxc-mobile-only {
        max-width: 100px;
      }
    }

    &__topBadgeWrapper {
      position: absolute;
      right: 10px;
      top: 16px;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
      width: 100%;
      justify-content: center;

      &.spacer {
        padding: 16px 16px 18px 16px;
      }

      @include rxc-desktop-only {
        padding: 16px 16px 16px 0px;
      }

      @include rxc-mobile-only {
        padding: 16px;
      }

      &__title {
        @include rxc-mobile-only {
          font-family: $rxc-rayban-lato-font;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: $rxc-black-rayban;
        }

        font-family: $rxc-rayban-lato-font;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #1f1f24;

        &__badgespacer {
          @include rxc-mobile-only {
            font-family: $rxc-rayban-lato-font;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: $rxc-black-rayban;
          }

          font-family: $rxc-rayban-lato-font;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: $rxc-black-rayban;
          margin-bottom: 4px;
        }
      }

      &__description {
        &__text {
          margin: 0px;
          width: 100%;
          font: 14px $rxc-rayban-lato-font;

          & ul {
            list-style: none;
            padding: 0px;
            margin: 16px 0px 0px;

            & li {
              margin-bottom: 8px;
              display: block;

              &::before {
                content: "";
                background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667Z' fill='%23247534'/%3e%3cpath d='M3.33332 4.05699L1.47132 2.19532L0.528656 3.13799L3.33332 5.94299L7.80466 1.47132L6.86199 0.528656L3.33332 4.05699Z' fill='%23247534' transform='translate(3.5%2c4.8)'/%3e%3c/svg%3e") no-repeat center center/cover;
                // position: absolute;
                width: 16px;
                height: 16px;
                z-index: 100;
                display: inline-block;
                margin: 2px 9px -3px 0px;
              }

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }

        &__chips {
          margin: 0px;
          width: 100%;
          color: #222222 !important;
          font: 14px $rxc-rayban-lato-font;

          & ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: wrap;
            list-style: none;
            padding: 0px;
            margin: 16px 0px 0px;

            @include rxc-mobile-only {
              display: none;
            }

            & li {
              height: 25px;
              width: fit-content;
              margin-bottom: 8px;
              margin-right: 8px;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px 8px 7px 8px;
              background: #fff;
              border: 1px solid #1f1f24;
              border-radius: 100px;
              color: #1f1f24 !important;
            }
          }
        }

        @include rxc-mobile-only {
          font-family: $rxc-rayban-lato-font;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.16px;
          text-align: left;
          color: $rxc-black-rayban;
        }

        font-family: $rxc-rayban-lato-font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 0.5px;
        text-align: left;
        color: #1f1f24;
      }

      &__spacer {
        height: 1px;
        background-color: #e2e2e5;
        margin-top: 16px;
        margin-bottom: 16px;
      }

      &__spacerMobile {
        height: 1px;
        background-color: #e2e2e5;
        margin-bottom: 8px;
        width: 100%;
      }

      &__learnMorePriceContainer {
        display: flex;
        //flex-direction: row;
        justify-content: space-between;

        @include rxc-mobile-only {
          justify-content: flex-end;
        }

        &.onlyPrice {
          position: relative;
          align-self: flex-end;
          margin-bottom: 0px;
        }
      }

      &__price {
        position: relative;
        align-self: flex-end;
        margin-bottom: 0px;
        font-family: $rxc-rayban-lato-font;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        color: #222 !important;
      }

      &__learnMore {
        box-sizing: border-box;
        align-self: flex-start;
        display: flex;
        font-family: $rxc-rayban-lato-font !important;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        //letter-spacing: 0.16px;
        text-align: left;
        color: #666 !important;
        text-decoration: underline;
        //height: 54px;
        min-width: 115px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 8px 0px 16px;

        &::before {
          content: "";
          position: absolute;
          height: 54px;
          width: 16px;
          top: 0;
          left: -16px;
          pointer-events: all;
        }

        @include rxc-mobile-only {
          // color: $rxc-black-rayban;
          // font-size: 0px;
          // width: 21.5px;
          // height: 21.5px;
          // content: url("data:image/svg+xml, %3Csvg width='23' height='22' viewBox='0 0 23 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5 1.75C6.39137 1.75 2.25 5.89137 2.25 11C2.25 16.1086 6.39137 20.25 11.5 20.25C16.6086 20.25 20.75 16.1086 20.75 11C20.75 5.89137 16.6086 1.75 11.5 1.75ZM0.75 11C0.75 5.06294 5.56294 0.25 11.5 0.25C17.4371 0.25 22.25 5.06294 22.25 11C22.25 16.9371 17.4371 21.75 11.5 21.75C5.56294 21.75 0.75 16.9371 0.75 11ZM11.5 10.25C11.9142 10.25 12.25 10.5858 12.25 11V15C12.25 15.4142 11.9142 15.75 11.5 15.75C11.0858 15.75 10.75 15.4142 10.75 15V11C10.75 10.5858 11.0858 10.25 11.5 10.25ZM11.5 6.25C11.0858 6.25 10.75 6.58579 10.75 7C10.75 7.41421 11.0858 7.75 11.5 7.75H11.51C11.9242 7.75 12.26 7.41421 12.26 7C12.26 6.58579 11.9242 6.25 11.51 6.25H11.5Z' fill='%23222222'/%3E%3C/svg%3E");
          // background-repeat: no-repeat;
        }
      }
    }

    &__transitionColors {
      z-index: 3;

      @include rxc-desktop-only {
        padding: 16px 0 0;
      }

      @include rxc-mobile-only {
        padding: 0 20px 16px;
      }

      .selectedColor {
        margin-bottom: 8px;
        font-family: "Lato" !important;
        font-size: 14px;

        p {
          margin: 0px;
          font-weight: bold;

          span {
            font-weight: 400;
          }
        }
      }

      .colors {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .color {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &__img {
            @include rxc-mobile-only {
              height: 40px;
              width: 40px;
            }
          }

          &.selected {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              border: 2px solid #1f1f24;
              border-radius: 4px;

              @include rxc-desktop-only {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.Step-Brand .StepCard__layout__imgcontainer__img {
  max-width: 64px;
}

.Step-Treatments .StepCard__layout__imgcontainer__img {
  max-width: 64px;
}