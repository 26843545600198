@import "../../mixins/global";

.PrescriptionUploadForm__PupillaryDistance__optionsContainer .margin {
  margin: 0 10px !important;
}

.PrescriptionUploadForm {
  &__subtitle {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  &__submitContainer {
    display: flex;
    position: relative;
    height: 100%;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 50px;

    @include rxc-mobile-only {
      margin-top: 20px;
    }
  }

  &__submitButton {
    width: fit-content;
    height: 40px;
    border: unset;
    padding: 8px 32px;
    margin-right: -8px;
    background-color: #1f1f24;
    position: relative;
    font-family: "Oswald";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;

    @include rxc-mobile-only {
      width: 100%;
      margin-right: unset;
    }

    &:hover {
      box-shadow: 0px 0px 5.89091px rgba(40, 41, 61, 0.04),
        0px 11.7818px 23.5636px rgba(96, 97, 112, 0.16);
    }
  }

  &__button {
    width: fit-content;
    height: 40px;
    padding: 10px 35px 10px 35px;
    border-radius: 100px;
    background-color: #557b92;
    border: none;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-family: "sofia-pro";
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 0.5px;
  }

  &__UploadBox {
    &__container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: fit-content;
      padding: 24px;
      border: solid 1px #222222;
      border-radius: 8px;
      min-height: 160px;

      &.error {
        border: solid 1px #e32118;
      }

      &__spinnerContainer {
        display: flex;
        justify-content: center;
      }

      &__topContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      &__bottomContainer {
        width: 100%;
        margin-top: 10px;
      }

      &__changeMethod {
        font-family: "Lato";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #666;
        text-decoration: underline;
        cursor: pointer;

        @include rxc-mobile-only {
          display: none;
        }
      }

      &__changeMethodMobile {
        display: none;

        @include rxc-mobile-only {
          display: unset;
          font: 12px "sofia-pro-bold", Verdana, Arial, sans-serif;
          text-decoration: underline;
          color: #557b92;
          cursor: pointer;
          letter-spacing: 0.16px;
          line-height: 16px;
          margin-top: 15px;
          align-self: flex-end;
        }
      }
    }
  }

  &__UploadResult {
    &__container {
      display: flex;
      align-items: center;

      svg {
        min-width: 16px;
      }
    }

    &__unsuccessfulIconContainer {
      margin-right: 10px;
      margin-top: 2px;
    }

    &__successText {
      text-transform: uppercase;
      font-family: "Lato";
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #1c8846;
      margin-left: 10px;
    }

    &__errorText {
      font: 14px "Lato";
      letter-spacing: 0.5px;
      color: #e32118;

      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  &__FilePreview {
    &__container {
      display: flex;

      &__filePreview {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 84px;
        height: 90px;
        margin-right: 15px;
        cursor: pointer;

        & .Loader__container {
          margin-left: 0;
        }

        &.noPreview {
          cursor: unset !important;
        }

        img {
          max-width: 84px;
          max-height: 90px;
          margin: auto;
        }
      }

      &__fileInfo {
        display: flex;
        flex-flow: column;
        margin-top: 10px;

        &__nameAndSize {
          font-family: "Lato";
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: #666;
          word-break: break-all;
        }

        &__uploadDifferent {
          font-family: "Lato";
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          color: #666;
          margin-top: 8px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  &__UploadError {
    &__container {
      &__message {
        margin-bottom: 17px;
        font-family: "Lato";
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #666;
      }
    }
  }

  &__CustomCheckbox {
    &__checkbox {
      -webkit-appearance: none;
      background-color: white;
      border: 1px solid rgb(118, 118, 118);
      min-width: 16px !important;
      height: 16px !important;
      border-radius: 2px !important;
      display: inline-block;
      position: relative;

      &.marginTop {
        margin-top: 4px;
      }

      &.checked {
        background: black;
        border: none !important;

        &:after {
          content: "" !important;
          position: absolute !important;
          width: 10px;
          height: 10px;
          top: 3px !important;
          left: 3px !important;
          background-size: contain;
          background-image: url(https://assets.lenscrafters.com/extra/image/LensCrafters/global/check.png);
          background-repeat: no-repeat;
        }
      }

      &:hover {
        border: 1px solid rgb(90, 90, 90);
      }
    }
  }

  .AgreementCheckbox {
    &__container {
      display: flex;
      align-items: flex-start;
      margin-top: 24px;

      &.invisible {
        display: none;
      }
    }

    &__text {
      font-family: Lato;
      font-size: 14px;
      margin-left: 10px;
      line-height: 24px;
    }

    &__tooltip {
      position: relative;
      height: 16px;
      width: 16px;
      min-width: 16px;
      margin-left: 8px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      display: none !important;

      &:hover {
        cursor: pointer;

        .AgreementCheckbox__tooltip__body {
          display: block;
        }
      }

      @include rxc-mobile-only {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: -3px;
      }

      @include rxc-desktop-only {
        margin-top: 4px;
      }

      &__body {
        display: none;
        position: absolute;
        top: -160px;
        left: -180px;
        background: #f7f6f4;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 8px !important;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        // outline: 1px solid #e5e5e5;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        padding: 16px;
        line-height: 150%;

        @include rxc-desktop-only {
          width: 375px;
          height: 137px;
        }

        @include rxc-mobile-only {
          left: -195px;
          top: -192px;
          width: 90vw;
        }

        &::after {
          content: "";
          position: absolute;
          width: 29px;
          height: 18px;
          bottom: -16px;
          left: 46%;
          z-index: 100;
          background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%23f7f6f4'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;

          @include rxc-mobile-only {
            left: 190px;
          }
        }
      }
    }
  }

  &__Separator {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 5%;
    background-color: $rxc-grey-separator;
  }
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}