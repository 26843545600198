@import "../../mixins/global";

.FrameSection {
  &__container {
    &__price {
      margin-left: 4px;

      &__strikePrice {
        text-decoration: line-through;
        color: #666666;
        margin-left: 4px;
      }
    }

    &__title {
      margin: unset;
    }

    &__bridge {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 14px;
    }

    &__bridgeDescription {
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      margin: 0px 8px;
    }

    &__tooltip {
      width: fit-content;
      justify-content: center;
      max-width: 289px;
      border-radius: 4px;

      @include rxc-mobile-only {}

      &__icon {
        position: relative;
        min-width: 16px;
        width: 18px;
        height: 18px;
        background: no-repeat;
        background-position: top;
        background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg id='Group'%3e%3cpath id='Vector' d='M9 0C4.03763 0 0 4.03763 0 9C0 13.9624 4.03763 18 9 18C13.9624 18 18 13.9624 18 9C18 4.03763 13.9624 0 9 0ZM9 17.25C4.45088 17.25 0.75 13.5491 0.75 9C0.75 4.45088 4.45088 0.75 9 0.75C13.5491 0.75 17.25 4.45088 17.25 9C17.25 13.5491 13.5491 17.25 9 17.25Z' fill='%231F1F24'/%3e%3cpath id='Vector_2' d='M9.375 6.75H8.625V13.5H9.375V6.75Z' fill='%231F1F24'/%3e%3cpath id='Vector_3' d='M9.375 4.5H8.625V5.25H9.375V4.5Z' fill='%231F1F24'/%3e%3c/g%3e%3c/svg%3e");

        &:hover {
          cursor: pointer;

          .FrameSection__container__tooltip__icon__text {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        &__text {
          display: none;
          position: absolute;
          top: -90px;
          left: -109px;
          background: #666;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 2px !important;
          border-radius: 2px;
          font-family: $rxc-rayban-lato-font;
          font-size: 12px;
          font-weight: 400;
          padding: 8px 16px;
          line-height: 160%;
          z-index: 10000;
          width: 224px;
          color: white;
          // margin-right: 8px;

          &.visible {
            display: flex;
          }

          &__close {
            position: absolute;
            top: 10px;
            right: 20px;
            width: 16px;
            height: 16px;
            background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg id='035' clip-path='url(%23clip0_2591_2807)'%3e%3cpath id='Vector' d='M15.9023 0.56899L15.431 0.0976562L7.99999 7.52866L0.56899 0.0976562L0.0976562 0.56899L7.52866 7.99999L0.0976562 15.431L0.56899 15.9023L7.99999 8.47132L15.431 15.9023L15.9023 15.431L8.47132 7.99999L15.9023 0.56899Z' fill='white'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_2591_2807'%3e%3crect width='16' height='16' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
          }

          @include rxc-desktop-only {
            // width: 375px;
          }

          @include rxc-mobile-only {}

          &::after {
            content: "";
            position: absolute;
            width: 29px;
            height: 18px;
            bottom: -16px;
            left: 46%;
            z-index: 100;
            background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%23666'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            @include rxc-mobile-only {
              // left: 301px;
            }
          }
        }
      }
    }
  }
}