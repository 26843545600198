@import "../../../mixins/global.scss";

.LensColor {
  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      background-color: $rxc-black-rayban;
      padding: 8px 20px 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-rayban-oswaldMed-font;
      font-weight: 700;
      left: 0px;
      z-index: 0;
      margin-bottom: 16px;
    }

    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 10px 35px 10px 35px;
    background-color: $rxc-black-rayban;
    border: none;
    position: relative;
    z-index: 1;
    font-size: $rxc-sun-default-16-fontSize;
    font-family: $rxc-rayban-lato-font;
    font-weight: 700;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: -2%;

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include rxc-mobile-only {
        bottom: 18%;
        left: 50%;
        transform: translate(-50%, 0);
        position: relative;
        margin-top: 11.5px;
      }

      @include rxc-desktop-only {
        position: absolute;
        left: 30%;
        bottom: 15%;
      }

      &.borderOutline {
        @include rxc-mobile-only {
          background-color: white;
          padding: 2px;
          padding-bottom: 16px;
          border: 1px solid $rxc-black-rayban;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-radius: 8px;
          margin-top: 16px;
          margin-bottom: 50px;
        }
      }

      &__detailBox {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        margin: 16px 16px 8px 16px;
        word-break: break-word;
        align-self: flex-start;

        @include rxc-desktop-only {
          position: absolute;
          bottom: 80%;
          right: -3%;
          width: 280px;
        }

        @include rxc-mobile-only {
          margin: 16px 0 8px 0;
        }

        &__content {
          display: flex;
          flex-direction: column;
          position: relative;

          @include rxc-desktop-only {
            &::before {
              content: "";
              position: absolute;
              top: 6px;
              left: -14.5px;
              width: 1px;
              height: 100%;
              background-color: #222;
            }

            &::after {
              content: "";
              position: absolute;
              left: -19px;
              bottom: -7px;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              background-color: #222;
            }
          }

          &__textContainer {
            display: flex;
            margin-bottom: 8px;

            &__text {
              margin-right: 8px;
              font-family: $rxc-rayban-lato-font;
              font-weight: 800;
              font-size: $rxc-sun-default-12-fontSize;
            }

            &__inner {
              font-family: $rxc-rayban-lato-font;
              font-size: $rxc-sun-default-12-fontSize;
              font-weight: normal;

              @include rxc-desktop-only {
                max-width: 100px;
              }

              @include rxc-small-mobile-only {
                max-width: 95%;
                word-break: break-word;
              }
            }
          }
        }
      }
    }

    &_containerNotSuitable {
      display: flex;
      justify-content: center;
      align-items: center;

      @include rxc-mobile-only {
        bottom: 22%;
        left: 50%;
        position: relative;
        margin-top: 11.5px;
        transform: translate(-50%, 0);
      }

      @include rxc-desktop-only {
        position: absolute;
        right: -32px;
        bottom: 11% !important;
        transform: translateX(-50%);
        z-index: 1;
      }

      &.fixed {
        @include rxc-desktop-only {
          position: fixed;
          bottom: 175px !important;
        }
      }

      &.borderOutline {
        @include rxc-mobile-only {
          padding: 2px;
          padding-bottom: 20px;
          border: 1px solid $rxc-black-rayban;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-radius: 0px;
          margin-top: 16px;
          margin-bottom: 50px;
        }
      }

      &__detailBox {
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        margin: 16px 20px 8px 20px;
        word-break: break-word;
        align-self: flex-start;

        @include rxc-desktop-only {
          position: absolute;
          bottom: 80%;
          right: -3%;
          width: 280px;
        }

        @include rxc-mobile-only {
          margin: 16px 0 8px 0;
        }

        &__content {
          display: flex;
          flex-direction: column;
          position: relative;

          @include rxc-desktop-only {
            &::before {
              content: "";
              position: absolute;
              top: 6px;
              left: -14.5px;
              width: 1px;
              height: 100%;
              background-color: #222;
            }

            &::after {
              content: "";
              position: absolute;
              left: -19px;
              bottom: -7px;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              background-color: #222;
            }
          }

          &__textContainer {
            display: flex;
            line-height: 24px;
            margin-bottom: 4px;
            font-size: 14px;

            &__text {
              margin-right: 8px;
            }

            &__inner {
              font-family: $rxc-rayban-lato-font;

              @include rxc-desktop-only {
                max-width: 60%;
              }

              @include rxc-small-mobile-only {
                max-width: 95%;
                word-break: break-word;
              }
            }
          }
        }
      }
    }
  }

  &__alert {
    &_container {
      display: flex;
      flex-direction: column;
      padding-left: 37px;
      background: #e6e6e6;
      padding-right: 37px;
      padding-top: 16px;
      padding-bottom: 16px;
      margin-left: 40px;
      margin-right: 40px;

      & p {
        margin: 0;
      }

      & a {
        cursor: pointer;
      }
    }

    &_title {
      position: relative;
      font-family: $rxc-rayban-lato-font, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: $rxc-black-rayban;

      &__icon {
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjYuNSIgc3Ryb2tlPSIjMTExMTExIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy41IDEwLjVINi41TDYuNSA1LjVINy41TDcuNSAxMC41Wk03LjUgNC41SDYuNVYzLjVINy41VjQuNVoiIGZpbGw9IiMxMTExMTEiLz4KPC9zdmc+Cg==");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 14px;
        height: 14px;
        display: inline-block;
        position: absolute;
        left: 53px;
        cursor: pointer;

        &.visible {
          display: flex;
        }
      }
    }

    &_description {
      font-family: $rxc-rayban-lato-font;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $rxc-black-rayban;

      & a {
        font-family: $rxc-rayban-lato-font;
        font-size: 13px;
        color: $rxc-black-rayban;
        line-height: 20.5px;
        letter-spacing: 0;
        text-decoration: underline;
      }
    }

    &__banner--tooltip {
      font-family: $rxc-rayban-lato-font;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.16px;
      z-index: 1;
      width: fit-content;
      justify-content: center;
      max-width: 289px;
      padding: 23px 26px 23px 31px;
      background-color: $rxc-white-color-1;
      -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      border-radius: 0px;
      position: absolute;
      top: 40px;
      left: 12px;

      @include rxc-mobile-only {
        margin-top: 10%;
      }
    }

    &__banner--tooltip:after {
      content: "";
      position: absolute;
      border: 10px solid transparent;
      border-bottom: 10px solid $rxc-white-color-1;
      bottom: 100%;
      left: 45.5%;
    }
  }

  &__container {
    &_separator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $rxc-grey-separator;

      @include rxc-mobile-only {
        margin-bottom: 10px;
      }
    }

    &__color_category_description {
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding-top: 10px;
    }
  }

  &__groupsContainer {
    display: flex;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    @include rxc-desktop-only {
      flex-flow: row wrap;
      row-gap: 8.5px;
      column-gap: 32px;
      padding-bottom: 75px;
      margin-right: -16px;
      margin-top: 15px;
    }

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
      column-gap: 0px;
      margin-top: 27px;
    }

    &__less_space {
      @include rxc-desktop-only {
        margin-top: 5px;
      }

      @include rxc-mobile-only {
        margin-top: 15px;
      }

      @include rxc-small-mobile-only {
        margin-top: 10px;
      }
    }
  }

  //scrollbar mobile removed
  &__groupsContainer::-webkit-scrollbar {
    width: 0 !important;
    opacity: 0 !important;
    display: none !important;
  }

  &__alreadyIncluded {
    &__container {
      &__list {
        list-style-type: none;
        margin: 0;
        padding-inline-start: 0;

        & li {
          font-family: $rxc-sgh-helvetica-regular;
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #555555;
          margin-bottom: 10px;

          @include rxc-small-mobile-only {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      &__info--icon {
        margin-left: 10px;
        cursor: pointer;
        height: 14.33px;
        width: 14.33px;
        background-repeat: no-repeat;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljk5OTkyIDEuODMzMzFDNC41OTQxNiAxLjgzMzMxIDEuODMzMjUgNC41OTQyMiAxLjgzMzI1IDcuOTk5OThDMS44MzMyNSAxMS40MDU3IDQuNTk0MTYgMTQuMTY2NiA3Ljk5OTkyIDE0LjE2NjZDMTEuNDA1NyAxNC4xNjY2IDE0LjE2NjYgMTEuNDA1NyAxNC4xNjY2IDcuOTk5OThDMTQuMTY2NiA0LjU5NDIyIDExLjQwNTcgMS44MzMzMSA3Ljk5OTkyIDEuODMzMzFaTTAuODMzMjUyIDcuOTk5OThDMC44MzMyNTIgNC4wNDE5NCA0LjA0MTg4IDAuODMzMzEzIDcuOTk5OTIgMC44MzMzMTNDMTEuOTU4IDAuODMzMzEzIDE1LjE2NjYgNC4wNDE5NCAxNS4xNjY2IDcuOTk5OThDMTUuMTY2NiAxMS45NTggMTEuOTU4IDE1LjE2NjYgNy45OTk5MiAxNS4xNjY2QzQuMDQxODggMTUuMTY2NiAwLjgzMzI1MiAxMS45NTggMC44MzMyNTIgNy45OTk5OFpNNy45OTk5MiA3LjQ5OTk4QzguMjc2MDYgNy40OTk5OCA4LjQ5OTkyIDcuNzIzODQgOC40OTk5MiA3Ljk5OTk4VjEwLjY2NjZDOC40OTk5MiAxMC45NDI4IDguMjc2MDYgMTEuMTY2NiA3Ljk5OTkyIDExLjE2NjZDNy43MjM3OCAxMS4xNjY2IDcuNDk5OTIgMTAuOTQyOCA3LjQ5OTkyIDEwLjY2NjZWNy45OTk5OEM3LjQ5OTkyIDcuNzIzODQgNy43MjM3OCA3LjQ5OTk4IDcuOTk5OTIgNy40OTk5OFpNNy45OTk5MiA0LjgzMzMxQzcuNzIzNzggNC44MzMzMSA3LjQ5OTkyIDUuMDU3MTcgNy40OTk5MiA1LjMzMzMxQzcuNDk5OTIgNS42MDk0NiA3LjcyMzc4IDUuODMzMzEgNy45OTk5MiA1LjgzMzMxSDguMDA2NTlDOC4yODI3MyA1LjgzMzMxIDguNTA2NTkgNS42MDk0NiA4LjUwNjU5IDUuMzMzMzFDOC41MDY1OSA1LjA1NzE3IDguMjgyNzMgNC44MzMzMSA4LjAwNjU5IDQuODMzMzFINy45OTk5MloiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==");
      }

      &__toolptip {
        font-family: $rxc-sgh-helvetica-regular;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16px;
      }
    }
  }

  &__polarized {
    &_container {
      display: flex;
      flex-direction: row;
      margin-top: 18px;
      position: relative;
      align-items: center;
      margin-bottom: 18px;

      @include rxc-mobile-only {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      &_titleContainer {
        font-family: $rxc-rayban-lato-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-black-rayban;
        order: 2;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        padding: 5px -4px 0px 8px;

        @include rxc-mobile-only {
          margin-top: 2px;
        }
      }

      &__moreinfo {
        color: $rxc-black-rayban;
        flex-flow: row;
        justify-content: center;
        order: 3;
        font-size: 0px;
        width: 15.3px;
        height: 15.3px;
        margin-left: 8.8px;
        // content: url(https://img.icons8.com/pastel-glyph/2x/info.png);
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03Ljk5OTkyIDEuODMzMzFDNC41OTQxNiAxLjgzMzMxIDEuODMzMjUgNC41OTQyMiAxLjgzMzI1IDcuOTk5OThDMS44MzMyNSAxMS40MDU3IDQuNTk0MTYgMTQuMTY2NiA3Ljk5OTkyIDE0LjE2NjZDMTEuNDA1NyAxNC4xNjY2IDE0LjE2NjYgMTEuNDA1NyAxNC4xNjY2IDcuOTk5OThDMTQuMTY2NiA0LjU5NDIyIDExLjQwNTcgMS44MzMzMSA3Ljk5OTkyIDEuODMzMzFaTTAuODMzMjUyIDcuOTk5OThDMC44MzMyNTIgNC4wNDE5NCA0LjA0MTg4IDAuODMzMzEzIDcuOTk5OTIgMC44MzMzMTNDMTEuOTU4IDAuODMzMzEzIDE1LjE2NjYgNC4wNDE5NCAxNS4xNjY2IDcuOTk5OThDMTUuMTY2NiAxMS45NTggMTEuOTU4IDE1LjE2NjYgNy45OTk5MiAxNS4xNjY2QzQuMDQxODggMTUuMTY2NiAwLjgzMzI1MiAxMS45NTggMC44MzMyNTIgNy45OTk5OFpNNy45OTk5MiA3LjQ5OTk4QzguMjc2MDYgNy40OTk5OCA4LjQ5OTkyIDcuNzIzODQgOC40OTk5MiA3Ljk5OTk4VjEwLjY2NjZDOC40OTk5MiAxMC45NDI4IDguMjc2MDYgMTEuMTY2NiA3Ljk5OTkyIDExLjE2NjZDNy43MjM3OCAxMS4xNjY2IDcuNDk5OTIgMTAuOTQyOCA3LjQ5OTkyIDEwLjY2NjZWNy45OTk5OEM3LjQ5OTkyIDcuNzIzODQgNy43MjM3OCA3LjQ5OTk4IDcuOTk5OTIgNy40OTk5OFpNNy45OTk5MiA0LjgzMzMxQzcuNzIzNzggNC44MzMzMSA3LjQ5OTkyIDUuMDU3MTcgNy40OTk5MiA1LjMzMzMxQzcuNDk5OTIgNS42MDk0NiA3LjcyMzc4IDUuODMzMzEgNy45OTk5MiA1LjgzMzMxSDguMDA2NTlDOC4yODI3MyA1LjgzMzMxIDguNTA2NTkgNS42MDk0NiA4LjUwNjU5IDUuMzMzMzFDOC41MDY1OSA1LjA1NzE3IDguMjgyNzMgNC44MzMzMSA4LjAwNjU5IDQuODMzMzFINy45OTk5MloiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==");
        background-repeat: no-repeat;
        cursor: pointer;

        &__tooltip {
          font-family: $rxc-rayban-lato-font;
          font-size: $rxc-sun-default-12-fontSize;
          z-index: 1;
          width: fit-content;
          justify-content: center;
          max-width: 289px;
          padding: 23px 26px 23px 31px;
          background-color: $rxc-white-color-1;
          -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
          -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
          border-radius: 4px;

          @include rxc-small-mobile-only {
            width: 210px;
          }

          &.visible {
            display: flex;
          }
        }
      }

      &_switch {
        order: 1;
        flex-flow: row wrap;
        justify-content: flex-start;
        position: left;
        border-radius: 12px;

        @include rxc-mobile-only {
          margin-top: 20px;
          margin-bottom: 0px;
        }

        .__react_component_tooltip {
          visibility: visible !important;
        }
      }

      &_promoTitle {
        font-family: $rxc-rayban-lato-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-red-color-2;
        text-transform: uppercase;
        margin: 0 0 0 7px;

        @include rxc-mobile-only {
          margin: 0 0 0 8px;
        }
      }

      & p {
        margin: 0;
        font-family: $rxc-rayban-lato-font;
        font-size: $rxc-sun-default-14-fontSize;
        color: $rxc-grey2-sun-default;
        line-height: 14px;
        letter-spacing: 0;
        text-transform: uppercase;
      }
    }
  }

  &__detailsContainer_mobile {
    width: 90%;

    @include rxc-desktop-only {
      display: none;
    }
  }

  &__detailsContainer_desktop {
    @include rxc-mobile-only {
      display: none;
    }
  }
}