@import "../../mixins/global.scss";

.LensCard {
  &__container {
    border: 1px solid #E2E2E5;
    border-radius: 8px;
    padding: 2rem 10.4px 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    background-color: #fff;
    cursor: pointer;
    width: 132px;
    min-height: 187px;
    line-height: 160%;
    min-width: 140px;
    position: relative;
    outline-offset: -2px;

    &:active,
    &.selected {
      border: 1.2px solid #1F1F24;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
    }

    .StepPrice {
      margin-top: auto;
    }

    .StepPrice__container__inner__strikePrice {
      display: none;
    }
  }

  &__color {
    display: block;
    width: 56px;
    height: 56px;
    background-size: contain;
    @include loader();

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      visibility: hidden;
    }

    &.hideLoader {
      background-image: none;

      img {
        visibility: visible;
      }
    }
  }

  // &__color__img {
  //   display: block;
  //   width: auto;
  //   max-width: 56px;
  //   height: 56px;
  //   min-height: 56px;
  // }

  &__name,
  &__price {
    font-family: var(--font-secondary)
  }

  &__name {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
    font-size: 14px;
    text-align: center;
  }

  &__price {
    font-weight: 500;
    margin-top: auto;
  }

  &__discount {
    font-family: var(--font-secondary);
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #e80c00;
    position: absolute;
    top: 12px;
  }

  &__polarized {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-weight: 700;
    margin-top: 4px;
    font-size: 12px;

    &::before {
      width: 20px;
      height: 20px;
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.6225 8.6832C17.1387 12.5765 13.3077 16.328 8.18073 13.5335C2.93823 10.6752 -5.37552 0.7857 10.3887 1.00695C16.4547 1.0917 18.2825 3.3642 17.6225 8.6832Z" fill="%231F1F24"/><path d="M17.6225 8.6832C17.1387 12.5764 13.3077 16.328 8.18073 13.5335C2.93823 10.6752 -5.37552 0.7857 10.3887 1.00695C16.4547 1.0917 18.2825 3.3642 17.6225 8.6832" stroke="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.2612 2.65548C11.6142 2.23173 8.98769 2.58798 6.04169 3.52098C5.61944 3.65448 5.39069 4.14648 5.32469 4.39848C5.25719 4.65048 5.16194 4.95498 5.10194 5.17923C5.04344 5.40273 5.16794 5.38998 5.31119 5.30673C5.45369 5.22423 6.20144 4.86723 6.50219 4.76148C7.82894 4.29198 11.4214 3.01998 13.2529 3.49023C14.0052 3.68373 14.4079 4.34073 14.1859 5.18073C13.8012 6.63723 12.4137 7.29723 11.0082 7.90848C10.7487 8.02173 10.6992 7.87323 10.7434 7.70523L11.6944 4.10598C11.7582 3.86898 11.5729 3.82098 11.3479 3.86823L10.7839 4.05123C10.6527 4.09248 10.6159 4.17273 10.5829 4.29948L9.54044 8.28573C9.25394 8.34873 8.92244 8.41923 8.53619 8.49798C8.40119 8.52498 8.32844 9.10548 8.59694 9.09348C8.85384 9.0695 9.10889 9.02867 9.36044 8.97123C9.36044 8.97123 8.54219 12.1347 8.49044 12.3312C8.43794 12.5262 8.55944 12.5285 8.68769 12.5015C8.81594 12.4745 9.06569 12.389 9.28019 12.3245C9.49469 12.2607 9.59894 12.092 9.64394 11.9247L10.4247 8.90973C10.4367 8.86773 10.4772 8.71323 10.5987 8.65473C13.1509 7.60623 14.5924 6.57123 14.9809 5.10123C15.1887 4.31673 14.9089 3.07848 13.2612 2.65548Z" fill="white"/></svg>');
    }
  }
}