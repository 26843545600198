@import "..//..//mixins/global.scss";

.Price {
  &__container {
    display: flex;
    text-align: left;
    align-items: baseline;

    &__inner {
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.16px;
      margin-bottom: 8px;

      &__strikePrice {
        margin-top: 10px;
        margin-right: 6px;
        margin-left: 2px;
        text-align: right;
        color: #1f1f24;
        text-decoration: line-through;

        &__display {
          display: none;
        }
      }
      &__price {
        margin-top: 10px;
        font-family: $rxc-rayban-lato-font;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.5px;
        text-align: right;
        color: $rxc-red-color-2;
      }
      &__green {
        color: #1c8846 !important;
      }
    }
  }
}
