@import "../../mixins/global";

.InsuranceTooltip {
  &__icon {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-left: 8px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

    @include rxc-mobile-only {
      width: 16px;
      height: 16px;
      min-width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 10.6667V8' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 5.33337H8.00667' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  &__container {
    opacity: 0;
    position: fixed;
    max-width: 306px;
    transform: translateY(calc(-100% - 18px));
    background-color: white;
    padding: 16px 45px 24px 16px;
    box-shadow: 0px 0px 2.94545px rgb(40 41 61 / 4%),
      0px 5.89091px 11.7818px rgb(96 97 112 / 16%);
    border-radius: 6px;

    @include rxc-mobile-only {
      transform: translateY(calc(100% + 18px));
    }

    &.fadeIn {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-duration: 1s;
    }

    &.invisible {
      display: none;
    }

    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &.fadeOut {
      opacity: 0;
      animation-name: fadeOutOpacity;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-duration: 1s;
    }

    @keyframes fadeOutOpacity {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    &__text {
      font: 12px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
      color: #222222;
    }

    &__arrowDown {
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0%;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid white;
      transform: translateY(90%);
      -webkit-filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
      filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
    }

    &__arrowUp {
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 11px solid white;
      -webkit-filter: drop-shadow(0px -4px 2px rgba(96, 97, 112, 0.05));
      transform: translateY(-90%);
      filter: drop-shadow(0px -4px 2px rgba(96, 97, 112, 0.05));
    }

    &__close {
      width: 12px;
      height: 12px;
      min-width: 12px;
      position: absolute;
      top: 10px;
      right: 14px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z' fill='%23222222' stroke='%23222222' stroke-width='0.75'/%3E%3C/svg%3E%0A");
    }
  }
}
