@import "../../mixins/global";

.HeaderPrice {
  &__container {
    display: flex;

    p {
      font-family: $rxc-rayban-lato-font !important;
    }

    @include rxc-mobile-only {
      width: 100%;
      align-self: center;
      justify-content: center;
    }

    &__label {
      font-family: $rxc-rayban-lato-font;
      font-size: $rxc-sun-default-16-fontSize;
      color: $rxc-grey2-sun-default;
      align-self: center;

      p {
        @include rxc-smartphone-only {
          font-size: 3vw !important;
        }

        margin: 0 !important;
      }

      &__frameLensPrice {
        @include rxc-mobile-only {
          font-family: $rxc-rayban-lato-font !important;
          font-weight: 600;
          font-size: clamp(10px,
              calc(0.625rem + ((1vw - 3.2px) * 7.2727)),
              14px);
          min-height: 0vw;
          text-transform: uppercase;
          white-space: nowrap;
        }
      }

      &__price {
        @include rxc-mobile-only {
          font-family: $rxc-rayban-lato-font !important;
          font-weight: 600;
          font-size: clamp(12px, calc(0.375rem + ((1vw - 3px) * 9.3333)), 14px);
          min-height: 0vw;
          text-transform: uppercase;
        }
      }
    }

    &__value {
      margin-left: 4px;
      box-sizing: border-box;
      font-family: $rxc-rayban-lato-font;
      font-weight: 700;
      font-size: 18px;

      @include rxc-mobile-only {
        font-family: $rxc-rayban-lato-font !important;
        font-weight: 700;
        font-size: 14px;
        align-self: center;
        text-transform: uppercase;
      }
    }

    &__discountvalue {
      margin-left: 4px;
      box-sizing: border-box;
      font-family: $rxc-rayban-lato-font;
      font-size: $rxc-sun-default-16-fontSize;
      color: $rxc-grey2-sun-default;
      text-decoration: line-through;

      @include rxc-mobile-only {
        font-family: $rxc-rayban-lato-font !important;
        font-size: 12px;
        align-self: center;
        text-transform: uppercase;
      }
    }

    .step-button-price {
      color: white;

      & .StepPrice {
        &__container {
          &__inner {
            display: flex;
            align-items: baseline;

            &__strikePrice {
              padding-top: 0;

              @include rxc-mobile-only {
                margin-top: 0px;
              }

              @include rxc-smartphone-only {
                margin-top: 0px;
                font-size: 2.5vw !important;
              }
            }

            &__price {
              font-size: 14px;

              @include rxc-smartphone-only {
                font-size: 3vw !important;
              }
            }

            &__priceBlack {
              @include rxc-smartphone-only {
                font-size: 3vw !important;
              }
            }
          }
        }
      }
    }
  }

  &--red {
    .StepPrice__container__inner__price {
      color: var(--Primary-Color, #E80C00) !important;
    }
  }

  & .Loader__container {
    width: 15px;
    height: 15px;

    & .Loader__icon {
      width: 20px;
      height: 20px;
    }
  }

  &__insurance {
    @include rxc-mobile-only {
      font-family: $rxc-rayban-lato-font !important;
      font-size: $rxc-sun-default-14-fontSize !important;
      text-align: left;
      color: #666666 !important;
      text-decoration: underline !important;
      font-weight: 700 !important;
      margin-top: unset !important;
      //text-transform: uppercase; //come da richiesta RXPANEL-1129
    }
  }
}