@import "../../mixins/global";

.StepContainer {
  &__container {
    @include rxc-desktop-only {
      margin: 0px 32px !important;
      padding-bottom: 5px;
    }

    &__blur {
      filter: opacity(25%);
    }
  }
}
.learnMoreButton {
  cursor: pointer;
  width: fit-content;
}
