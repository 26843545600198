@import "../../../mixins/global.scss";
.Thickness {
  &__separator {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin-bottom: 24px;
  }

  &__second-title {
    flex-grow: 0;
    font-family: $rxc-rayban-lato-font;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: #333;
    margin-bottom: 24px;
  }

  &__container {
    @include rxc-mobile-only {
      margin-top: 6px;
    }
    margin-bottom: 10px;
    &__separator {
      height: 1px;
      background-color: $rxc-grey-separator;
      margin: 5px 0 24px 0;
      @include rxc-mobile-only {
        margin: 0 0 18px 0;
      }
    }
    &__title {
      .StepTitle__container {
        padding: 0 0 16px 0;
        &__title {
          font-family: Oswald;
          font-size: 24px;
          font-stretch: normal;
          font-style: normal;
          font-weight: 500;
          color: #1f1f24;
          text-align: start;
          text-transform: uppercase;
          line-height: 1;
          @include rxc-mobile-only {
            display: none;
          }
        }
        &__titleMobile {
          display: block;
          font-size: 20px;
          text-align: left;
          font-family: "Oswald";
          color: #1f1f24;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: normal;
          text-align: left;
          text-transform: uppercase;
          @include rxc-desktop-only {
            display: none;
          }
        }
      }
    }
  }
  &__groupsContainer {
    margin: 14px 0 26px;
    display: flex;
    gap: 6px;
    overflow-x: auto;
    @include rxc-desktop-only {
      display: none;
      max-width: 0px;
      max-height: 0px;
      margin: 0px;
    }

    .Thickness_group_btn {
      background-color: #fff;
      cursor: pointer;
      text-transform: capitalize;
      margin: 0 16px 0 0;
      padding: 8px 16px;
      border-radius: 100px;
      border: solid 1px #d8d8d8;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.16px;

      @include rxc-desktop-only {
        display: none;
        max-width: 0px;
        max-height: 0px;
      }

      &_selected {
        cursor: pointer;
        text-transform: capitalize;
        padding: 8px 16px;
        margin: 0 16px 0 0;
        color: #ffffff;
        background-color: $rxc-black-rayban;
        border-radius: 100px;
        border: solid 1px $rxc-black-rayban;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.16px;
        @include rxc-desktop-only {
          display: none;
        }
      }
    }
  }
}
.ThicknessTreatment {
  &__groupsContainer {
    margin: 14px 0 26px;
    display: flex;
    gap: 6px;

    .Thickness_group_btn {
      background-color: #fff;
      cursor: pointer;
      text-transform: capitalize;
      margin: 0 16px 0 0;
      padding: 8px 16px;
      border-radius: 100px;
      border: solid 1px #d8d8d8;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.16px;

      &_selected {
        cursor: pointer;
        text-transform: capitalize;
        padding: 8px 16px;
        margin: 0 16px 0 0;
        color: #ffffff;
        background-color: $rxc-black-rayban;
        border-radius: 100px;
        border: solid 1px $rxc-black-rayban;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.16px;
      }
    }
  }
}
