@import "../../mixins/global";

.BottomBarTotalPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0px;
  padding: 0px;

  &__pricerow {
    align-self: flex-end;
    align-items: baseline;
    display: flex;
    width: max-content;
    align-items: center;

    &__label {
      p {
        font-family: $rxc-rayban-lato-font;
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin: 0;
      }
    }

    &__price {
      margin-left: 10px;

      &--red {
        .StepPrice__container__inner__price {
          color: var(--Primary-Color, #E80C00) !important;
        }
      }
    }

    &__green {
      color: #247534;
    }
  }

  &__gstrow {
    display: none;
  }
}