@import "../../../mixins/global.scss";

.TransitionColor {
  &__container {
    display: flex;

    @include rxc-desktop-only {
      flex-flow: row wrap;
      row-gap: 32px;
      column-gap: 32px;
    }

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
      column-gap: 16px;
    }
  }

  &__btn {
    @include rxc-mobile-only {
      width: 90%;
      margin-left: 0px;
      position: relative;
      background-color: #1f1f24;
      padding: 8px 20px 8px 20px;
      font-size: $rxc-sun-default-16-fontSize;
      font-family: $rxc-rayban-lato-font;
      left: 0px;
      z-index: 0;
      margin-bottom: 16px;
    }
    width: fit-content;
    height: 40px;
    margin: 0;
    padding: 10px 35px 10px 35px;
    background-color: #1f1f24;
    border: none;
    position: relative;
    z-index: 1;
    font-size: $rxc-sun-default-14-fontSize;
    font-weight: 500;
    text-align: center;
    color: $rxc-white-color-1;
    text-transform: uppercase;
    font-family: $rxc-rayban-lato-font;

    &_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $rxc-white-color-1;

      @include rxc-mobile-only {
        bottom: 18%;
        left: 50%;
        transform: translate(-50%, 0);
        position: relative;
        margin-top: 16px;
      }
      @include rxc-desktop-only {
        position: absolute;
        left: 30%;
        bottom: 15%;
      }

      &.borderOutline {
        @include rxc-mobile-only {
          padding: 2px;
          border: 1px solid $rxc-grey2-sun-default;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-radius: 8px;
          margin-top: 16px;
        }
      }

      &__textContainer {
        display: none;
        @include rxc-mobile-only {
          display: flex;
          flex-direction: column;
          word-wrap: break-word;
          margin: 16px 16px 8px 16px;
          word-break: break-all;
          align-self: flex-start;
        }

        &__text {
          display: none;

          @include rxc-mobile-only {
            display: flex;
            font-family: "sofia-pro";
            font-size: $rxc-sun-default-12-fontSize;
            word-wrap: break-word;
            margin-bottom: 4px;
            max-width: 100%;
            word-break: break-all;
          }
        }
      }
    }
  }

  &__card {
    height: 141px;
    width: 133px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0px 16px;
    margin-top: 23.5px;
    margin-bottom: 4px;
    border-radius: 8px;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    border: solid 2px #ffffff;
    background-color: #ffffff;
    font-family: $rxc-rayban-lato-font;
    cursor: pointer;
    @include rxc-mobile-only {
      min-width: 133px;
    }

    &.selected {
      border: solid 2px $rxc-black-rayban;
    }

    &:hover {
      border-color: $rxc-black-rayban;
    }

    &.disabled {
      cursor: default;
      border: unset;
      background-color: #f2f4f5;
      opacity: 60%;
    }

    &__img {
      width: 44px;
      height: 57px;
      margin: 0 17px 8px;
    }

    &__text {
      flex-grow: 0;
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.5px;
      text-align: left;
      color: $rxc-black-rayban;
      margin: 0px;
      white-space: nowrap;

      & p {
        margin: 0;
      }
    }
  }
}

.TransitionColor__container .SkipContainer {
  &__container {
    display: inherit;
    flex-flow: inherit;
    row-gap: inherit;
    column-gap: inherit;

    @include rxc-mobile-only {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
