@import "../../mixins/global.scss";

.FrameCard {
  &__container {
    border: 1px solid #e2e2e5;
    border-radius: 8px;
    padding: 1rem 0.5rem;
    background-color: #fff;
    cursor: pointer;
    width: 102.67px;
    min-width: 96px;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline-offset: -2px;

    &:active,
    &.selected {
      border: 1.2px solid #1f1f24;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
    }

    &:disabled {
      &:active {
        border: 1px solid #e2e2e5;
      }
    }
  }

  &__color {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: contain;
    margin-bottom: 1rem;
    //@include loader();

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-family: var(--font-secondary);
    text-align: center;
    line-height: 150%;
  }
}