@import "../../mixins/global";

.StepTitle {
  &__container {
    flex-flow: column nowrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include rxc-mobile-only {
      padding: 16px 0px;
    }

    &__title {
      font-family: "Oswald";
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      margin-bottom: 16px;
      color: #1F1F24;
      text-align: left;
      letter-spacing: normal;
      text-transform: uppercase;
      height: 40px;
      padding: 0 0 16px;

      @include rxc-mobile-only {
        display: none;
        font-size: 20px;
        text-align: center;
      }
    }

    &__titleMobile {
      display: none;

      @include rxc-mobile-only {
        display: block;
        font-size: 24px;
        text-align: left;
        font-family: "Oswald";
        color: #1F1F24;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 160%;
        letter-spacing: normal;
        text-align: left;
        text-transform: uppercase;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    &__subtitle {
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: 0.16px;
      color: #1f1f24;
      margin-bottom: 16px;

      @include rxc-mobile-only {
        margin-top: 18px;
      }

      &__margin {
        margin-top: 18px;
      }
    }

    &__pillsSubtitle {
      box-sizing: border-box;
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      text-align: left;
      color: $rxc-grey-sun-default;
      margin-top: 0;
      word-wrap: break-word;
      line-height: 1.6;

      @include rxc-desktop-only {
        margin-bottom: 24px;
      }
    }
  }
}