@import "../../mixins/global";

.PrescriptionModalContinue {
  &__overlay {
    position: absolute;
    background-color: rgba(51, 51, 51, 0.6);
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: fit-content;
    background-color: white;
    z-index: 101;
    display: block;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include rxc-desktop-only {
      min-width: 600px;
      max-width: 600px;
      height: 240px;
    }

    @include rxc-mobile-only {
      width: 90%;
    }

    &__title {
      font-family: Oswald;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-transform: uppercase;
      text-align: center;
      color: #1f1f24;
      text-align: start;
      padding: 5px 0 16px 0;

      @include rxc-mobile-only {
        display: none;
        font-size: 20px;
        text-align: center;
      }
    }

    &__text {
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #1f1f24;
      @include rxc-desktop-only {
        max-width: 320px;
      }
    }

    &__buttons {
      display: flex;
      @include rxc-mobile-only {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
      }
    }

    &__continueButton {
      margin-bottom: 15px;
      border-radius: 1px !important;
      background-color: #1f1f24 !important;
      color: #ffffff !important;
      border: none;
      text-transform: uppercase;
      font-family: Oswald !important;
      font-size: 14px !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      border: 1px solid #1f1f24 !important;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        margin-top: 40px;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-right: 20px;
        font-size: 14px !important;
        width: fit-content;
        margin-top: 20px;
        height: 40px !important;
        width: 129px !important;
        padding: 9px 20px;
      }
    }

    &__cancelButton {
      margin-top: 20px;
      margin-bottom: 15px;
      border-radius: 1px !important;
      background-color: #ffffff !important;
      color: #1f1f24 !important;
      border: none;
      text-transform: uppercase;
      font-family: Oswald !important;
      font-size: 14px !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      cursor: pointer;

      @include rxc-mobile-only {
        width: 100%;
        padding: 0px 10px 0px 12px;
      }

      @include rxc-desktop-only {
        margin-left: 20px;
        font-size: 14px !important;
        width: fit-content;
        padding: 9px 24px;
        height: 40px !important;
        width: 129px !important;
      }
    }
  }
}
