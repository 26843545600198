@import "../../mixins/global";

.WarningBanner {

  &__container {
    font-family: "Lato";
    font-weight: 400;
    display: flex;
    align-items: flex-start !important;
    background-color: $rxc-default-background-color;
    margin: 16px 0px 0px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    padding: 8px 13px;
    min-width: 100%;
    gap: 8px;

    &__tooltip {
      font-family: "Lato";
      font-size: $rxc-sun-default-12-fontSize;
      z-index: 1;
      width: fit-content;
      justify-content: center;
      position: absolute;
      margin-top: 3.8%;
      left: 20px;
      max-width: 289px;
      padding: 23px 26px 23px 31px;
      background-color: $rxc-white-color-1;
      -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      border-radius: 4px;

      @include rxc-mobile-only {
        margin-top: 10px;
      }
    }

    &__icon {
      min-width: 16px;
      width: 16px;
      height: 16px;
      background: no-repeat;
      background-position: top;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_1262_4587)'%3E%3Cpath d='M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667Z' fill='%23222222'/%3E%3Cpath d='M8.66667 6.66667H7.33333V12H8.66667V6.66667Z' fill='%23222222'/%3E%3Cpath d='M8.66667 4H7.33333V5.33333H8.66667V4Z' fill='%23222222'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1262_4587'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }

    &__text {
      font-family: "Lato";
      font-weight: 400;
      font-size: 14px;
      display: block !important;
      white-space: break-spaces;
      color: #1F1F24;

      @include rxc-desktop-only {
        max-width: 80%;
      }
    }
  }
}