@import "../../mixins/global";

.ReadOnlyTable {
  &__container {
    display: flex;
    flex-direction: column;

    @include rxc-desktop-only {
      margin-top: 16px;
    }

    &__top {
      display: flex;

      @include rxc-mobile-only {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &__cell {
        font-family: "Lato";
        height: 38px;
        width: 128px;
        text-align: center;
        border: 1px solid #b0b0b0;
        margin-left: -1px;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6f6f6;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        //line-height: 160%;
        color: #1f1f24;

        @include rxc-mobile-only {
          width: unset;
          font-size: 15px;
        }
      }
    }

    &__right {
      @include rxc-mobile-only {
        display: flex;
        flex: 2;

        & div {
          display: flex;
          flex-direction: column;
        }
      }

      &__cell,
      &__cellValue {
        height: 38px;
        width: 128px;
        text-align: center;
        border: 1px solid #b0b0b0;
        margin-left: -1px;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__cell {
        background-color: #f6f6f6;
        font-family: "Lato";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #1f1f24;
      }

      &__cellValue {
        font-family: "Lato";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #666;
        line-height: 20px;
      }
    }

    &__pd {
      &__container {
        display: flex;
        margin: 24px 0px;

        @include rxc-mobile-only {
          margin: 24px 0px 0px;
        }

        & div {
          width: 126px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include rxc-mobile-only {
            width: unset;
          }
        }

        &__label {
          color: #000;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          border: 1px solid #b0b0b0;
          background-color: #f6f6f6;
          margin-left: -1px;
          font-family: "Lato";

          @include rxc-mobile-only {
            flex: 1;
          }
        }

        &__value {
          color: #000000;
          text-align: center;
          font-family: "Lato";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #b0b0b0;
          margin-left: -1px;
          align-items: center;
        }
      }

      &__containerDouble {
        & div {
          min-width: 128px;
          min-height: 38px;
          display: flex;
          justify-content: center;

          @include rxc-mobile-only {
            min-width: 115px;
            max-width: min-content;
          }
        }

        display: flex;
        flex-direction: column;
        margin: 24px 0px;

        @include rxc-mobile-only {
          margin: 24px 0px 0px;
        }

        &__label {
          color: var(--base-text-dark, var(--color-brand-text-dark, #000));
          text-align: center;
          font-family: $rxc-rayban-lato-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid #b0b0b0;
          background-color: #f6f6f6;
          margin-left: -1px;
          margin-bottom: -1px;
          align-items: center;
        }

        &__value {
          color: #1f1f24;
          text-align: center;
          font-family: $rxc-rayban-lato-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%;
          border: 1px solid #b0b0b0;
          margin-left: -1px;
          margin-bottom: -1px;
          align-items: center;
          background-color: #f6f6f6;
        }
      }
    }
  }
}
