@import "../../mixins/global";

.MainContent {
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    z-index: 1;
    border-bottom: solid 1px #d3d3d3;
    justify-content: space-between;

    @include rxc-mobile-only {
      flex-flow: column nowrap;
    }
  }
}
