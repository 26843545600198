.GVPTreatment {
  &__groupsContainer {
    margin: 0px 0 26px;
    display: flex;
    gap: 6px;

    .GVP_group_btn {
      background-color: #fff;
      cursor: pointer;
      text-transform: capitalize;
      margin: 0 16px 0 0;
      padding: 8px 16px;
      border-radius: 100px;
      border: solid 1px #d8d8d8;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.16px;

      &_selected {
        cursor: pointer;
        text-transform: capitalize;
        padding: 8px 16px;
        margin: 0 16px 0 0;
        color: #ffffff;
        background-color: #1f1f24;
        border-radius: 100px;
        border: solid 1px #1f1f24;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0.16px;
      }
    }
  }
}
