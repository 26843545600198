@import "../../mixins/global";

.WarrantyOptions {
  &__price_container {
    display: flex;
    align-items: center;

    &__text {
      font-weight: bold;
      font-size: 14px;
      display: flex;
      font-family: $rxc-rayban-lato-font;
      text-decoration: underline;
    }

    &__digit {
      display: flex;
      font-family: $rxc-rayban-lato-font;
      font-weight: 400;
      //margin-top: 1px;

      & .Price__container__inner {
        margin: 0px;
      }

      & .Price__container__inner__price {
        font-family: $rxc-rayban-lato-font;
        font-size: 14px;
        color: $rxc-black-rayban;
        font-weight: 400;
      }
    }

    &__addText {
      display: flex;
      font-family: $rxc-rayban-lato-font;
      margin-right: 5px;
      font-size: 14px;
    }
  }

  &__warrantyContainer {
    display: flex;
    min-height: 64px;
    align-items: center;

    @include rxc-desktop-only {
      margin-top: 14px;
    }

    & input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: #fafafa;
      border: 1px solid $rxc-black-rayban;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 7px;
      border-radius: 2px;
      display: inline-block;
      position: unset;
      margin: 1px 8px 0 4px;
      opacity: unset;
      width: 14px;
      height: 14px;
    }
    & input[type="checkbox"]:active,
    & input[type="checkbox"]:checked:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
    & input[type="checkbox"]:checked {
      background-color: black !important;
      border: 1px solid #adb8c0;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: white;
      border-radius: 2px;
    }
    /* Tick commentata */
    /* & input[type=checkbox]:checked:after {
            content: '\2714';
            font-size: 14px;
            position: relative;
            top: -11px;
            left: -5.5px;
            color: white;
        } */
  }
}
.Tooltip {
  &__tooltip {
    font-family: $rxc-rayban-lato-font;
    font-size: 12px;
    z-index: 1;
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 13%;
    max-width: 289px;
    padding: 30px;
    opacity: 1 !important;
    background-color: $rxc-white-color-1;
    -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: $rxc-black-rayban;

    @include rxc-mobile-only {
      margin-top: 10%;
    }
  }

  &__text {
    display: flex;
    margin-bottom: 8px;
    white-space: pre-line;
    vertical-align: bottom;
  }
}
.WarrantyOptions__warrantyContainer a {
  margin-top: 10px;
  color: $rxc-violet2-glasses;
  text-decoration: underline;
  font: 12px "sofia-pro", Verdana, Arial, sans-serif;
}
