@import "../../mixins/global";

.ScrollView {
  &__toBottom {
    width: 32px;
    height: 32px;
    position: fixed;
    right: 25%;
    bottom: 83px;
    z-index: 1;
    cursor: pointer;

    @include rxc-mobile-only {
      display: none;
    }
  }

  &__container {
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;

    &.insuranceLoadingOpacity {
      opacity: 0.3;
    }

    @include rxc-mobile-only {
      padding-right: 0;
    }

    @include rxc-desktop-only {
      height: calc(100vh - 72px - 70px);
    }

    //RXPANEL-1466
    /* &::-webkit-scrollbar {
            @include rxc-desktop-only {
                display: none;
            }
        } */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #d8d8d8;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #767676;
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #767676;
    }

    &.review {
      @include rxc-desktop-only {
        height: calc(100vh - 72px - 70px - 81px);
      }
      @include rxc-mobile-only {
        background-color: white;
        height: 100%;
      }
    }
  }

  &__innerContainer {
    height: 100%;

    @include rxc-mobile-only {
      padding: 0 18px;
    }
    @media screen and (min-width: 375px) and (max-width: 424px) {
      padding: 0 15px;
    }
  }

  /*     &__fade { 
        position: absolute; 
        bottom: 0; 
        left: 0;
        width: 100%; 
        text-align: center; 
        margin: 0; padding: 30px 0; 
          
        /* "transparent" only works here because == rgba(0,0,0,0) */
  /*  background-image: linear-gradient(to bottom, transparent, black);
      }*/

  &__trackVertical {
    position: absolute;
    width: 2px;
    top: 50%;
    transform: translate(0, -50%);
    height: 95%;
    max-height: 300px;
    right: 31px;
    border-radius: 5px;
    background-color: #d3d3d3;
    display: none;
  }

  &__thumbVertical {
    border-radius: 5px;
    background-color: #326fa8;
    box-shadow: 0 0 8px 0 #d7d7d7;
  }

  &__scrollbar {
    @include rxc-mobile-only {
      overflow-y: scroll !important;
      background-color: #f5f5f5;
      -webkit-overflow-scrolling: touch;
    }

    @include rxc-desktop-only {
      flex: 1 1 50%;
      background-color: white;
    }
  }

  &__fade {
    content: "";
    background: linear-gradient(
      to top,
      rgba(251, 251, 251, 1) 0%,
      rgba(251, 251, 251, 0) 100%
    );
    position: fixed;
    width: 100%;
    height: 40px;

    @include rxc-mobile-only {
      bottom: 0px;
    }

    @include rxc-desktop-only {
      bottom: 71px;
    }
  }
}
