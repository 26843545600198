@import "../../mixins/global";

.Carousel {
  &__wrapper {
    display: flex;
    max-width: 1200px;
    position: relative;
    margin: 2rem 0 1rem 0;

    >button {
      top: 50%;
      height: 30px;
      width: 30px;
      padding: 0;
      color: #343f4f;
      cursor: pointer;
      position: absolute;
      text-align: center;
      background: #fff;
      border-radius: 50%;
      transform: translateY(-50%);
      border: none;
      outline: none;
      transition: transform 0.1s linear;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      z-index: 1000;

      @include rxc-desktop-only {
        display: none !important;
      }

      svg {
        display: block;
      }

      &:first-child {
        left: -12px;
      }

      &:last-child {
        right: -12px;

        svg {
          transform: rotate(180deg);
        }
      }

      &:active {
        transform: translateY(-50%) scale(0.9);
      }

      &:hover {
        background: #f2f2f2;
      }
    }
  }

  &__content {
    cursor: pointer;
    //overflow: hidden;
    //white-space: nowrap;
    scroll-behavior: smooth;
    display: flex;
    padding-bottom: 1rem;

    &::-webkit-scrollbar {
      height: 0;
    }

    &>* {
      object-fit: cover;
      user-select: none;
      margin-left: 8px;

      @include rxc-desktop-only {
        margin-left: 0px;
      }
    }

    &>*:first-child {
      margin-left: 0px;
    }

    @include rxc-desktop-only {
      flex-wrap: wrap;
      gap: 8px;
    }
  }
}