@import "../../mixins/global";

.StepPrice {
  & {
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    display: flex;
    text-align: center;
    align-items: center;

    &__insuranceLoading {
      align-items: center;
    }

    &__insuranceText {
      font-family: $rxc-rayban-lato-font;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: right;
      color: #247534 !important;
    }

    &__inner {
      @include rxc-mobile-only {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__strikePrice {
        text-align: right;
        font-family: $rxc-rayban-lato-font;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-decoration: line-through;
        letter-spacing: normal;
        color: #1f1f24 !important;

        @include rxc-desktop-only {
          margin-right: 6px;
        }

        @include rxc-mobile-only {
          position: relative;
          margin-top: 3px;
          margin-right: 8px;
        }
      }

      &__price {
        color: #1f1f24 !important;
        font-family: $rxc-rayban-lato-font !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: normal;
        text-align: right;

        @include rxc-mobile-only {
          display: inline-flex;
        }
      }

      &__priceBlack {
        font-family: $rxc-rayban-lato-font;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: right;
        color: #1f1f24 !important;

        @include rxc-mobile-only {
          display: inline-flex;
          margin-left: 4px;
        }
      }

      &__free {
        font-family: $rxc-rayban-lato-font;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: right;
        color: #1f1f24 !important;
      }

      &__green {
        color: #247534 !important;
      }

      &__GVPLabel {
        margin-right: 8px;
        font: 12px $rxc-rayban-lato-font;
      }

      &__labelContainer {
        display: flex;
        align-items: baseline;
      }
    }
  }

  &__vatIncluded {
    position: absolute;
    transform: translateY(22px);
    font: 12px $rxc-rayban-lato-font;
    font-weight: normal;
    color: #6d6d6d;
  }
}