@import "../../mixins/global";

// .margin {
//   margin: 0px 10px !important;

//   @include rxc-mobile-only {
//     margin: 0 10px !important;
//   }

//   @media screen and (min-width: 375px) and (max-width: 414px) {
//     margin: 0 !important;
//   }

//   @media screen and (min-width: 320px) and (max-width:360px) {
//     margin: 0 10px !important;
//   }
// }

.ScrollView {
  &__scrollbar {
    background-color: #fff !important;
  }
}

.PupillaryDistance {
  &__titleContainer {
    font-family: $rxc-rayban-lato-font;
    display: flex;
    align-items: center;

    &__title {
      margin-bottom: 8px;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #1f1f24;
    }

    &__link {
      font-size: 14px;
      cursor: pointer;
      text-decoration: underline;
      text-transform: uppercase;
      color: #1F1F24;
      max-width: fit-content;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 160%;
      letter-spacing: normal;
      font-family: "Oswald";
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 16px;

      &::before {
        content: "";
        height: 16px;
        width: 16px;
        min-width: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cg clip-path='url(%23clip0_1262_7843)'%3E%3Cpath d='M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM8 15.1667C4.324 15.1667 1.33333 12.176 1.33333 8.5C1.33333 4.824 4.324 1.83333 8 1.83333C11.676 1.83333 14.6667 4.824 14.6667 8.5C14.6667 12.176 11.676 15.1667 8 15.1667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 7.16667H7.33333V12.5H8.66667V7.16667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 4.5H7.33333V5.83333H8.66667V4.5Z' fill='%23333333'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1262_7843'%3E%3Crect width='16' height='16' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }

  &__subtitleContainer {
    margin-bottom: 16px;
    font-family: $rxc-rayban-lato-font;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    text-align: left;
    color: #222;
    pointer-events: none;

    &::after {
      content: attr(data-content);
      color: #557b92;
      text-decoration: underline;
      pointer-events: all;
    }
  }

  &__checkboxContainer {
    display: inline-flex;
    margin-top: 24px;
    align-items: center;
  }

  &__checkBoxText {
    font-family: $rxc-rayban-lato-font;
    margin-left: 16px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #222;
  }

  &__optionsContainer {
    font-family: $rxc-rayban-lato-font;
    color: #1F1F24;
    font-size: 14px;
    font-weight: 700;
    text-align: center;

    &__table {
      &.single {
        >div {
          height: 40px;

          select {
            height: 100%;
          }
        }
      }

      border: 1px solid $rxc-default-border-color;
      display: flex;
      max-width: max-content;

      &__leftCol {
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 40px);
      }

      &__leftLabel {
        border-top: 1px solid $rxc-default-border-color;
        display: grid;
        place-content: center;
      }

      &__col {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, 40px);

        >div:first-child {
          background: var(--light-gray, #F2F2F2);
        }
      }

      &__row {
        &:last-child {
          border-top: 1px solid $rxc-default-border-color;
          ;
        }

        &:first-child {
          display: grid;
          place-content: center;
        }
      }

      &>div {
        &:not(:last-child) {
          border-right: 1px solid $rxc-default-border-color;
        }

        width: 128px;


        &>div {
          width: 100%;
          height: 100%;
        }
      }

      &>div:first-child {
        display: grid;
        place-items: center;
        background: var(--light-gray, #F2F2F2);
      }
    }

    &__select {
      &_container {
        position: relative;
        padding: 8px;

        input {
          padding-left: 24px !important;
        }

      }
    }

    & select {
      width: 100% !important;
      margin: 0 !important;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding-left: 24px;
      background-color: #fff;
      -webkit-appearance: none;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: left;
      color: #1f1f24;
      border: none;

      @include rxc-mobile-only {
        width: 165px;
      }

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #d8d8d8;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #767676;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #767676;
      }

    }

    & select.__DoubleSelect {
      width: 169px;

      @include rxc-mobile-only {
        width: 174px;
      }

      @media screen and (min-width: 361px) and (max-width: 380px) {
        // width: 100px;
      }

      @media screen and (min-width: 320px) and (max-width: 360px) {
        // width: 75px;
      }

      .error {
        border-color: #e32118;
      }

    }
  }

  &__checkboxLabel {
    font-family: "Lato";
    font-weight: 700;
    margin-right: 10px;
    // margin-left: 10px;

    @include rxc-mobile-only {
      margin: 0px !important;
    }
  }

  &__bothValues {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    align-self: flex-start;
    margin: 4px 0px;
  }

  &__pdValueNotSelectedWarning {
    color: #B50900;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    margin-top: 24px;

    &::after {
      content: attr(data-content);
      color: #1F1F24;
      font-family: "Montserrat-Regular";
      font-weight: 700;
      line-height: 160%;
      text-decoration: underline;
    }
  }
}

.both_values {

  // flex-direction: column;s
  @include rxc-mobile-only {
    flex-direction: column;
    width: 100%;
  }
}