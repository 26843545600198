@import "../../mixins/global";

.BottomBar {
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  min-height: 72px;
  background-color: #ffffff;
  z-index: 1;

  @include rxc-small-mobile-only {
    position: unset !important;
  }

  &__hide {
    display: none;
  }

  &__legalBanner {
    display: flex;
    flex-flow: column nowrap;
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 72px;
    background-color: #ffffff;
    z-index: 1;

    @include rxc-mobile-only {
      display: none;
    }
  }

  .StepPrice__container__inner__GVPLabel {
    display: none;
  }

  &__mobileOnly {
    display: none;

    @include rxc-mobile-only {
      display: block;
    }
  }

  @include rxc-mobile-only {
    display: none;
  }

  &::before {
    content: "";
    height: 1px;
    margin-left: 24px;
    margin-right: 24px;
    background-color: #d8d8d8;
  }

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 0px;
    justify-content: space-between;
    margin: 0 60px 0 40px;

    &__sticky {
      min-height: 48px;
      position: relative;
      bottom: 0;
    }

    &__containerLastPage {
      justify-content: space-between;
      margin: 16px 24px 31.1px 24px;

      .savings {
        display: none;
      }
    }

    &__noInsurance {
      justify-content: flex-end;
    }

    &__left {
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      &__wrapper {
        display: flex;
        flex-direction: column;
      }

      &__insuranceWrapper {
        display: flex;
      }

      &__insuranceIcon {
        height: 24px;
        width: 24px;
        min-width: 24px;
        margin-left: 8px;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23287E4E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &__removeInsuranceLabel {
        font: 14px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 3px;
        width: fit-content;
      }

      &__insurance {
        text-align: right;
        box-sizing: border-box;
        display: flex;
        color: #666666;
        font-family: $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 0.16px;
        border: none;
        background: none;
        text-decoration: underline;
        cursor: pointer;
      }

      &__insuranceEnabled {
        font-family: $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: left;
        color: #247534;
      }

      &__addInsuranceButton {
        height: 40px;
        background-color: white;
        text-transform: uppercase;
        font-family: Oswald;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #1f1f24;
        padding: 0 32px;
        align-self: center;
        border: solid 1px $rxc-black-rayban;
        cursor: pointer;

        @include rxc-desktop-only {
          width: fit-content;
        }

        @include rxc-mobile-only {
          width: 100%;
          margin: 0px 0;
        }
      }
    }

    &__right {
      font-family: "Open Sans";
      font-weight: 800;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.5px;
      margin-right: 0px;
      margin-left: 20px;
      width: fit-content;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;

      &__noInsurance {
        margin-right: 0px !important;
      }

      &__legalBanner {
        margin: 4px 0 4px 6px;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: right;
        color: #666;
      }

      &__addToBagButton {
        height: 40px;
        width: fit-content;
        background-color: $rxc-red-color-2;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Oswald;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 0 32px;
        align-self: center;
        border: none;
        cursor: pointer;

        @include rxc-mobile-only {
          width: 100%;
        }
      }

      &__addToBagButtonSticky {
        width: fit-content;
        background-color: $rxc-red-color-2;
        color: #ffffff;
        text-transform: uppercase;
        font-family: Oswald;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 0 32px;
        align-self: center;
        border: none;
        cursor: pointer;

        @include rxc-mobile-only {
          z-index: 999;
          position: fixed;
          bottom: 0;
          bottom: env(safe-area-inset-bottom, 0);
          right: 0;
          margin: 0 -18px;
          width: calc(100% + 36px);
          padding: 20px 32px 20px;
          animation-duration: 250ms;
          animation-name: appearSticky;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  &.mixAndMatch {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    padding: 16px 0;
    min-height: auto;

    @include rxc-small-mobile-only {
      //min-block-size: 25vh;
      //-webkit-logical-height: 25vh
      height: calc(50vh - 150px) !important;
    }

    &::before {
      display: none;
    }

    .BottomBar__container {
      .BottomBarTotalPrice {
        margin: 0;
        width: 100%;

        &::after {
          content: "";
          position: relative;
          top: 8px;

          height: 1px;
          width: 100%;
          background-color: #E2E2E5;
        }

        &__pricerow {
          justify-content: space-between;
          width: 100%;
        }
      }

      &__right {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 24px;

        &__addToBagButton {
          margin-left: auto
        }
      }

      @include rxc-small-mobile-only {
        height: 80% !important;
      }
    }

    @include rxc-mobile-only {
      padding: 8px 0;

      .BottomBarTotalPrice {
        display: none;

        &::after {
          display: none;
        }

        &__pricerow {
          display: none;
        }
      }

      display: flex;
      width: 100%;

      .BottomBar__container {
        margin: 0;
        padding: 0 16px;


      }
    }
  }
}

@keyframes appearSticky {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}