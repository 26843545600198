@import "../../mixins/global";

.StepBadge {
  &__container {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    top: -14px;
    right: 16px;
    min-width: 121px;
    height: 24px;
    background: #e80c00;
    border-radius: 6px;
    z-index: 100;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;

    @include rxc-mobile-only {
      top: -14px;
      right: 8px;
    }

    &__star {
      display: flex;
      flex-flow: row nowrap;
      position: absolute;
      top: -14px;
      right: 16px;
      //width: 121px;
      height: 24px;
      background: #e80c00;
      border-radius: 6px;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;

      @include rxc-mobile-only {
        top: -14px;
        right: 8px;
      }

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.85133 9.89399C2.21421 10.2432 1.46938 9.67923 1.59098 8.93968L2.0014 6.44654L0.263311 4.68072C-0.252209 4.15707 0.0320439 3.24463 0.744521 3.13691L3.14666 2.77302L4.22113 0.504592C4.53969 -0.168197 5.46042 -0.168197 5.77877 0.504592L6.85324 2.77302L9.25538 3.13691C9.96764 3.24463 10.2523 4.15707 9.7368 4.68072L7.9985 6.44654L8.40892 8.93968C8.53052 9.67923 7.78591 10.2432 7.14856 9.89399L5.00006 8.717L2.85133 9.89399ZM5.00006 7.69413L7.55268 9.09289L7.06518 6.13063L9.13051 4.0326L6.27626 3.60037L5.00006 0.905142L3.72363 3.60037L0.869601 4.0326L2.93472 6.13063L2.44721 9.09289L5.00006 7.69413Z' fill='white'/%3e%3c/svg%3e");
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        margin: 0px 8px 0px 0px;
      }
    }
  }

  &__badge {
    &:last-child {
      margin-right: 0;
    }

    &_mostPopular {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      z-index: 1;
    }

    &_recommended {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      z-index: 1;
    }

    &_bestPrescription {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      z-index: 1;
      margin-right: 8px;
    }

    &_comingSoon {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      z-index: 1;
    }
  }
}