@import "../../mixins/global";


.Tabs {
  font-family: $rxc-rayban-lato-font;

  &__header {
    border-bottom: 1px solid $rxc-default-border-color;
    background: var(--light-gray, #F2F2F2);
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    li {

      background: var(--light-gray, #F2F2F2);
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      border: none;
      width: 100%;
      list-style: none;
      white-space: nowrap;
      cursor: pointer;
      color: #222;
      text-transform: uppercase;

      @include rxc-desktop-only {
        text-align: center;
      }

      a {
        padding: 8px 20px;
        width: 100%;
        height: 100%;
        display: block;

        &:focus,
        &:active {
          border: 1px solid black;
          border-right: 1px solid black;

          &:last-child {
            border: none;
          }
        }
      }

      &:not(:last-child) {
        border-right: 1px solid $rxc-default-border-color;
      }

      &:hover {

        &:last-child {
          border: none;
        }
      }
    }
  }

  &__content {
    font-family: $rxc-rayban-lato-font;
    padding: 24px 24px 8px 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;

    @include rxc-mobile-only {
      padding: 16px 16px 4px 16px;
    }

    .TabPanel {
      p {
        margin: 0 0 16px 0;
        color: #222;

        @include rxc-mobile-only {
          margin: 0 0 12px 0;
        }

        &.bold {
          margin-bottom: 4px;
          font-weight: 700;
          color: #1F1F24;
        }
      }
    }
  }
}