@import "../../mixins/global";

.HeaderProgressBar {
  &__container {
    width: 100%;
    background-color: #f0eae2;

    @include rxc-desktop-only {
      height: 3px;
      border-radius: 100px;
    }

    @include rxc-mobile-only {
      height: 5px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }

  &__bar {
    width: 100%;
    background-color: #f91515;
    transition: width 1s ease-in-out;

    @include rxc-desktop-only {
      height: 3px;
      border-radius: 100px;
    }

    @include rxc-mobile-only {
      height: 5px;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }
}
