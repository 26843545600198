@import "../../mixins/global";

.StepInReview {
  display: block;

  &__mainContainer {
    display: flex;
    // padding-top: 16px;
    width: 100%;

    &__contentContainer {
      display: flex;
      flex-direction: row;
      word-break: break-word;
      width: 100%;
      margin-bottom: 4px;

      &__titleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        @include rxc-mobile-only {
          flex: none !important;
        }
      }

      &__title_container {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        &__title {
          font-size: 14px;
          font-family: $rxc-rayban-lato-font;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: 160%;
          text-align: left;
          color: #666;
          display: flex;
          align-self: center;
          width: 140px;

          @include rxc-desktop-only {
            // padding-right: 0.5rem;
            margin-right: 16px;
          }
        }

        &__editContainer {
          @include rxc-mobile-only {
            padding-left: 10px;
          }

          @include rxc-smaller-mobile-only {
            padding-left: 4px;
          }

          &__editBtn {
            cursor: pointer;
            font-family: $rxc-rayban-lato-font;
            font-size: 14px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.16px;
            text-align: left;
            color: $rxc-violet2-glasses;
            text-decoration: underline;

            &body {
              font-family: $rxc-glasses-DMSans-font !important;
              font-size: 12px !important;
              font-weight: 600 !important;
            }

            &:first-child {
              text-transform: capitalize;
            }

            @include rxc-mobile-only {
              font-size: 12px;
              font-weight: 700;
              text-decoration: none;
            }
          }
        }
      }

      &__selectionContainer {
        display: block;

        .StepPrice {
          white-space: nowrap;
        }
      }

      &__selection {
        font-family: $rxc-rayban-lato-font;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 160%;
        text-align: left;
        color: #1F1F24;

        @include rxc-mobile-only {
          flex: 2;
          margin-left: 3px;
        }

        &.seeDetails {
          align-items: center;
          display: flex;
        }

        &__seeDetails {
          cursor: pointer;
          font-size: 12px;
          margin-left: 8px;
          text-decoration: underline;
        }
      }
    }

    &__priceContainer {
      justify-content: flex-end;
      display: flex;
      width: fit-content;

      .StepPrice__container__inner__strikePrice {
        font-size: 12px;
        line-height: 1.14;

        @include rxc-smaller-mobile-only {
          font-size: 10px;
        }
      }

      .StepPrice__container__inner__price {
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;

        @include rxc-smaller-mobile-only {
          font-size: 14px;
        }
      }
    }

    &__includedContainer {
      text-align: right;

      &__label {
        font-family: $rxc-rayban-lato-font;
        font-size: 16px;
      }
    }
  }
}