@import "../../mixins/global";

.LensesSection {
  &__chips {
    display: flex;
    gap: 8px;
    margin-top: 1rem;
    overflow-x: scroll;

    input {
      position: absolute;
      left: 0;
      width: 1px;
      clip: rect(1px, 1px, 1px, 1px);
      margin: 0 0 0 0%;
      padding: 0 0 0 0%;
      border: 0 !important;
      //overflow: hidden;


      // &:checked+label {
      //   border: 1px solid #1F1F24;
      // }
    }

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__chip {
    border-radius: 40px;
    padding: 6px 16px;
    border: 1px solid #E2E2E5;
    background: none;
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 16px;
    white-space: nowrap;
    cursor: pointer;
    //overflow: hidden;

    &.selected {
      border: 1.2px solid #1F1F24;
    }

    &__icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
      }
    }
  }
}