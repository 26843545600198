@import "../../../mixins/global.scss";

.TreatmentStep {
  display: block;
}

.Treatmentsfamily {
  &__container {
    .confirmButton {
      width: fit-content;
      height: 40px;
      background-color: #1f1f24 !important;
      border: none !important;
      position: relative;
      z-index: 1;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #ffffff !important;
      text-transform: uppercase;
      border-radius: 0px !important;
      font-family: $rxc-rayban-lato-font !important;
      cursor: pointer;
      padding: 10px 24px;
      display: flex;
      align-items: center;

      &__container {
        z-index: 1000;
        position: fixed;
        bottom: 90px;
        right: 40px;

        @include rxc-mobile-only {
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px;
          display: grid;
          place-items: center;
          background-color: #fff;
          border-top: 1px solid #ced4d9;
        }
      }
    }

    padding-bottom: 50px;
  }
}