@import "../../../mixins/global.scss";

.Review {
  &__productImageContainer {
    @include rxc-desktop-only {
      display: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__image {
      display: block;
      align-items: center;
      justify-content: center;
      width: auto;
      max-width: 100%;
      max-height: 220px;
    }
  }

  &__includedContainer {
    width: fit-content;
    height: fit-content;
    margin-bottom: 8px;

    &__checkBox {
      display: none !important;
    }

    &__accordion {
      &__open {
        display: block;

        & ul {
          margin: 12px 0;

          & li {
            font-family: $rxc-rayban-lato-font;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $rxc-black-rayban;
            padding-bottom: 0.3rem;

            &::marker {
              font-size: 0.6rem;
            }
          }
        }
      }

      &__closed {
        display: none;
      }

      &Button {
        font-family: $rxc-rayban-lato-font;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.29;
        letter-spacing: 0.5px;
        text-align: left;
        color: #666666;
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
      }

      &Button.closed::after {
        position: relative;
        top: 3px;
        margin-left: 4px;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.86195 5.52876C2.6016 5.78911 2.6016 6.21122 2.86195 6.47157L7.52862 11.1382C7.78896 11.3986 8.21108 11.3986 8.47142 11.1382L13.1381 6.47157C13.3984 6.21122 13.3984 5.78911 13.1381 5.52876C12.8777 5.26841 12.4556 5.26841 12.1953 5.52876L8.00002 9.72402L3.80476 5.52876C3.54441 5.26841 3.1223 5.26841 2.86195 5.52876Z' fill='%23557B92'/%3E%3C/svg%3E%0A");
      }

      &Button.open::after {
        position: relative;
        top: 3px;
        margin-left: 4px;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.138 10.4712C13.3983 10.2109 13.3983 9.78878 13.138 9.52843L8.47132 4.86177C8.21097 4.60142 7.78886 4.60142 7.52851 4.86177L2.86185 9.52843C2.6015 9.78878 2.6015 10.2109 2.86185 10.4712C3.1222 10.7316 3.54431 10.7316 3.80466 10.4712L7.99992 6.27598L12.1952 10.4712C12.4555 10.7316 12.8776 10.7316 13.138 10.4712Z' fill='%23557B92'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__topBadge {
    &__desktop {
      padding: 4px 16px;
      background-color: #f2f2f2 !important;
      font-family: "Lato";
      color: #1f1f24 !important;
      width: fit-content !important;
      height: fit-content !important;

      @include rxc-mobile-only {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        width: 100% !important;
      }

      @include rxc-desktop-only {
        margin: 8px 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        width: 95% !important;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      font-family: $rxc-rayban-lato-font;
      font-size: 16px;
      color: #182446 !important;
      background: var(--color-brand-secondary-light, #f3f1ff);
    }

    &__mobile {
      padding: 4px 19px;
      border-radius: 4px;
      border: solid 1px #e2e2e5;
      background-color: #f2f2f2;

      @include rxc-desktop-only {
        display: none;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 52px;
      font-family: $rxc-rayban-lato-font;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.6;
      color: #1f1f24;
      text-align: center;
    }
  }

  &__lensContainer {
    display: flex;
    width: 100%;

    .Review__frameContainer__contentContainer__title {
      @include rxc-desktop-only {
        margin-bottom: 24px;
      }

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .Review__frameContainer__contentContainer {

      @include rxc-mobile-only {
        flex-direction: row !important;
        align-items: baseline;
      }
    }
  }

  &__frameContainer {
    display: flex;
    width: 100%;
    padding: 16px 0px 0;

    @include rxc-mobile-only {
      padding: 16px 0px;
    }

    &__contentContainer {
      display: flex;
      flex-direction: column;

      // width: 80%;
      @include rxc-desktop-only {
        width: 68%;
      }

      @include rxc-mobile-only {
        width: 100%;
      }

      &__title {
        color: var(--dark, #1f1f24);
        font-family: "Oswald";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 160%;
        text-transform: uppercase;
        letter-spacing: normal;
        text-align: left;
        display: flex;
        justify-content: space-between;
        line-height: 160%;
        text-transform: uppercase;

        @include rxc-desktop-only {
          font-size: 14px;
          margin-bottom: 8px;
        }

        @include rxc-mobile-only {
          font-size: 14px;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }
      }

      &__name {
        font-family: $rxc-rayban-lato-font;
        letter-spacing: normal;
        text-align: left;

        color: var(--dark-gray, #666);

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;

        @include rxc-desktop-only {
          padding: 0 0.5rem 4px 0;
          font-size: 14px;
        }

        @include rxc-mobile-only {
          padding: 0 0.5rem 4px 0;
          font-size: 14px;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }
      }
    }

    &__price {
      text-align: right;
      color: $rxc-black-rayban;
      font-weight: bold !important;

      @include rxc-desktop-only {
        width: 32%;
      }

      @include rxc-mobile-only {
        width: 40%;
      }

      .StepPrice__container__inner__strikePrice {
        font-size: 12px !important;
        line-height: 1.6;

        @include rxc-smaller-mobile-only {
          font-size: 10px;
          width: fit-content;
        }

        @include rxc-mobile-only {
          margin-right: 8px;
          margin-top: 2px !important;
          width: fit-content;
        }
      }

      .StepPrice__container__inner__price {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;

        @include rxc-mobile-only {
          font-size: 14px !important;
          line-height: 1.6 !important;
          width: fit-content;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px !important;
          line-height: 1.6 !important;
          width: fit-content;
        }
      }

      .StepPrice__container__inner__priceBlack {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;

        @include rxc-mobile-only {
          font-size: 14px !important;
          line-height: 1.6 !important;
          width: fit-content;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px !important;
          line-height: 1.6 !important;
          width: fit-content;
        }
      }
    }
  }

  &__toggle {
    display: flex;

    @include rxc-desktop-only {
      margin-bottom: 16px;
    }

    @include rxc-mobile-only {
      margin-top: 16px;
    }

    &__label {
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #1f1f24;
      cursor: pointer;
      pointer-events: all !important;
    }

    &__icon {
      cursor: pointer;
      height: 16px;
      width: 16px;
      margin-left: 8px;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.99998 12.943L0.195312 5.13798L1.13798 4.19531L7.99998 11.057L14.862 4.19531L15.8046 5.13798L7.99998 12.943Z' fill='black'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &__stepsSeparator {
    min-height: 1px;
    opacity: 0.3;
    background-color: #e2e2e5;
    align-self: stretch;
    margin: 16px 0px;
  }

  &__priceSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include rxc-desktop-only {
      position: absolute;
      bottom: 72px;
      left: 0;
      right: 0;
      min-height: 81px;
      height: fit-content;
      box-shadow: 0 -5px 15px 0 rgb(0 0 0 / 5%);
      background-color: #ffffff;
    }

    @include rxc-mobile-only {
      margin-bottom: 13px;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      @include rxc-desktop-only {
        padding: 0 24px 16px;
      }

      @include rxc-mobile-only {
        padding: 16px 6px 24px;
      }

      &__label {
        font-family: $rxc-rayban-lato-font;
        font-size: 14px;
        color: #1f1f24;
      }

      &__labelTotal {
        display: block;
        text-transform: uppercase;

        @include rxc-mobile-only {
          display: none;
        }

        font-family: $rxc-rayban-lato-font;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #1f1f24;
      }

      &__labelTotalMobile {
        display: none;

        @include rxc-mobile-only {
          display: block;
          font-family: $rxc-rayban-lato-font;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.5px;
          text-align: left;
          color: $rxc-black-rayban;
          text-transform: uppercase;
        }
      }
    }

    &__paymentInstallment {
      // display: flex;
      display: none;
      cursor: pointer;
      align-self: flex-start;
      font-family: "sofia-pro-bold";
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #222222;

      @include rxc-mobile-only {
        &.marginTop {
          margin-top: 10px;
        }
      }

      @include rxc-desktop-only {
        padding: 0 40px;
      }
    }

    &__multipleInstallments {
      // display: flex;
      display: none;
      cursor: pointer;
      align-self: flex-start;
      font-family: "sofia-pro-bold";
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #222222;

      @include rxc-mobile-only {
        &.marginTop {
          margin-top: 10px;
        }
      }

      @include rxc-desktop-only {
        padding: 0 40px;
      }
    }

    &__separator {
      width: 100%;
      height: 1px;
      opacity: 0.3;
      background-color: #666666;
      margin-bottom: 19px;
    }
  }

  &__warrantyContainer {
    display: flex;
    margin-top: 32px;

    @include rxc-mobile-only {
      margin-top: 16px;
      margin-bottom: 32px;
    }

    &__text {
      display: flex;
      flex-flow: row;
      font-size: $rxc-sun-default-14-fontSize;
      margin-left: 8px;
      text-decoration: none;

      &__underlined {
        display: flex;
        font-weight: 700;
        flex-direction: row;
        text-decoration: underline;
        margin-top: -1.5px;
        padding: 0px 4px;
      }
    }
  }

  &__stickyWrapper {
    @include rxc-mobile-only {
      position: fixed;
      bottom: 0;
      background-color: white;
    }
  }

  &__buttonsMobileContainer {
    @include rxc-desktop-only {
      display: none;
    }

    @include rxc-mobile-only {
      margin: 0px 0 16px 0;
    }
  }

  &__placeholderPriceSUmmary {
    @include rxc-mobile-only {
      margin-top: 1.8rem;
    }
  }
}