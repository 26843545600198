@import "../../mixins/global";

.EarlyAccessTooltip {
  position: relative;
  &__container {
    position: absolute;
    width: 256px;
    height: fit-content;
    right: 0;
    top: -10px;
    transform: translateY(-100%);
    transition: 1s;
    background: #666666;
    border-radius: 2px;
    padding: 8px 16px 66px;
    box-shadow: 0px 0px 2.94545px rgb(40 41 61 / 4%),
      0px 5.89091px 11.7818px rgb(96 97 112 / 16%);
    z-index: 100;

    @include rxc-mobile-only {
      top: -12px;
    }

    &.invisible {
      opacity: 0;
    }

    &.gone {
      display: none;
    }

    &__arrow {
      position: absolute;
      right: 19px;
      width: 0;
      height: 0;
      bottom: -12px;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #666666;
      -webkit-filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
      filter: drop-shadow(0px 15px 7px rgba(96, 97, 112, 0.1));
    }

    &__body {
      font: 12px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
      color: #222222;
    }

    &__close {
      position: absolute;
      cursor: pointer;
      top: 8px;
      right: 16px;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjkwMjMgMC41Njg5OUwxNS40MzEgMC4wOTc2NTYyTDcuOTk5OTkgNy41Mjg2NkwwLjU2ODk5IDAuMDk3NjU2MkwwLjA5NzY1NjIgMC41Njg5OUw3LjUyODY2IDcuOTk5OTlMMC4wOTc2NTYyIDE1LjQzMUwwLjU2ODk5IDE1LjkwMjNMNy45OTk5OSA4LjQ3MTMyTDE1LjQzMSAxNS45MDIzTDE1LjkwMjMgMTUuNDMxTDguNDcxMzIgNy45OTk5OUwxNS45MDIzIDAuNTY4OTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
      background-repeat: no-repeat;
      background-size: cover;
    }

    h1 {
      font-family: $rxc-rayban-oswaldMed-font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      text-transform: uppercase;
      color: #ffffff;
      margin-top: 0;
    }

    p {
      color: #ffffff;
      font-family: $rxc-rayban-lato-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: #ffffff;
    }
    a {
      position: absolute;
      bottom: 16px;
      right: 16px;
      padding: 9px 24px;
      background: #ffffff;
      border: 1px solid #e2e2e5;
      border-radius: 1px;
      font-family: "Oswald";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      text-transform: uppercase;
      color: #1f1f24;
      cursor: pointer;
    }
  }
}
