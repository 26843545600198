@import "../../mixins/global";

.PaymentInstallmentsModal {
  &__container {
    display: block;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16),
      0 0 2.9px 0 rgba(40, 41, 61, 0.04);
    background-color: #ffffff;

    &__overlay {
      position: absolute;
      background-color: rgba(51, 51, 51, 0.6);
      top: 0;
      left: 0;
      z-index: 102;
      width: 100%;
      height: 100%;
    }
    &__popup {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #ffffff;
      height: auto;
      max-height: 80%;
      width: 960px;
      padding: 44px 40px 30px 40px;
      overflow: hidden;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;

      @include rxc-mobile-only {
        overflow: auto;
        padding: 30px 40px;
        margin: 0;
        width: calc(100% - 2 * 16px);
        height: auto;
        max-height: 90vh;
      }

      &__closeContainer {
        position: absolute;
        top: 20px;
        right: 20px;

        /* @include rxc-mobile-only {
          margin-bottom: 10px;
        } */
        &__close {
          display: block;
          content: "";
          background: image-set(
            url(#{$globalImageUrl}xclose.png) 1x,
            url(#{$globalImageUrl}xclose@2x.png) 2x,
            url(#{$globalImageUrl}xclose@3x.png) 3x
          );
          background: -webkit-image-set(
            url(#{$globalImageUrl}xclose.png) 1x,
            url(#{$globalImageUrl}xclose@2x.png) 2x,
            url(#{$globalImageUrl}xclose@3x.png) 3x
          );
          background-repeat: no-repeat;
          color: transparent;
          width: 24px;
          height: 24px;
          background-size: 100%;
          align-self: center;
          cursor: pointer;
          @include rxc-mobile-only {
            width: 20px;
            height: 20px;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__title {
          font-family: $rxc-rayban-oswaldMed-font;
          font-size: 20px;
          text-transform: uppercase;
          line-height: 32px;
          letter-spacing: normal;
          text-align: center;
          color: #1f1f24;
          margin-bottom: 10px;
        }

        &__subtitle {
          font-family: $rxc-rayban-lato-font;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #1f1f24;
        }

        &__payments_container {
          &__payment {
            display: flex;
            font-family: $rxc-rayban-lato-font;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: normal;
            text-align: left;
            color: #1f1f24;
            margin-top: 30px;
          }
        }

        &__footer {
          font-family: $rxc-rayban-lato-font;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #1f1f24;
          margin: 10px 0 20px 0;
        }

        &__exit_button_container {
          display: flex;
          justify-content: flex-end;
          &__exit_button {
            font-family: $rxc-rayban-oswaldMed-font;
            background-color: #1f1f24;
            min-height: 40px;
            padding: 0 21px;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            border: none;

            @include rxc-mobile-only {
              font-weight: 500;
              min-height: 40px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
