@import "../../mixins/global.scss";


.LensesSection {
  &__header {
    margin-bottom: 2rem;

    >* {
      margin-top: 1rem;

    }

    @include rxc-desktop-only {
      display: flex;
      align-items: center;
      justify-content: space-between;


    }
  }

  &__container {
    .Cards {
      display: flex;
      flex-direction: column;
    }

    &__price {
      margin-left: 4px;

      &__strikePrice {
        text-decoration: line-through;
        color: #666666;
        margin-left: 4px;
      }
    }
  }
}