@import "../../mixins/global";

.FullPrescriptionForm__innerContainer__form .error {
  border: 1px solid red !important;
}

.FullPrescriptionForm {
  &__separator {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: #e2e2e5;
    margin: 16px 0px;
  }

  &__subtitle {
    font-size: 16px;
    font-family: "Lato";
    margin-bottom: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 160%;
    letter-spacing: normal;
    text-align: left;
    color: #1F1F24;
  }

  &__readInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-family: "Oswald";
    font-family: "Lato";
    font-size: 14px;
    text-transform: uppercase;
    color: #222;

    &::before {
      content: "";
      height: 16px;
      width: 16px;
      min-width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'%3E%3Cg clip-path='url(%23clip0_1262_7843)'%3E%3Cpath d='M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM8 15.1667C4.324 15.1667 1.33333 12.176 1.33333 8.5C1.33333 4.824 4.324 1.83333 8 1.83333C11.676 1.83333 14.6667 4.824 14.6667 8.5C14.6667 12.176 11.676 15.1667 8 15.1667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 7.16667H7.33333V12.5H8.66667V7.16667Z' fill='%23333333'/%3E%3Cpath d='M8.66667 4.5H7.33333V5.83333H8.66667V4.5Z' fill='%23333333'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1262_7843'%3E%3Crect width='16' height='16' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    & {
      cursor: pointer;
      text-decoration: underline;
      color: #1F1F24;
      max-width: fit-content;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 160%;
      letter-spacing: normal;
      font-family: "Oswald";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }

    & img {
      vertical-align: sub;
    }

    & a {
      margin-left: 10px;
      font-size: 13px !important;
    }
  }

  &__samePrescriptionBothEyes {
    &__checkboxContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin: 16px 0px;

      span {
        font-size: 14px;
        font-weight: 400;
        font-family: "Lato";
        color: #1F1F24;
        line-height: 160%;
      }
    }
  }

  &__innerContainer {
    display: flex;
    font-family: "$rxc-rayban-lato-font-Regular";

    &__apply {
      display: flex;
      position: relative;
      bottom: 0;
      height: 100%;
      align-items: center;
      justify-content: center;

      @include rxc-mobile-only {
        border-top: 1px solid #e5e5e5;
      }

      &Button {
        width: fit-content;
        height: 40px;
        margin-top: 20px;
        padding: 0 10px;
        background-color: #1f1f24 !important;
        border: none !important;
        position: relative;
        z-index: 1;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #ffffff !important;
        text-transform: uppercase;
        border-radius: 0px !important;
        font-family: $rxc-rayban-lato-font !important;
        z-index: 1;

        @include rxc-desktop-only {
          position: fixed;
          bottom: 80px;
          right: 40px;
          margin-bottom: 0px;
        }

        @include rxc-desktop-only {
          width: 163px;
        }

        @include rxc-mobile-only {
          width: 100%;
          margin-bottom: 40px;
        }

        &_arrowRight {
          border: solid #ffffff !important;
          border-width: 0 1px 1px 0 !important;
          display: inline-block;
          padding: 1px 2px 2px 1px;
          margin-left: 3px;
          vertical-align: middle;
        }
      }
    }

    &_closed {
      font-family: "$rxc-rayban-lato-font-Regular";
      overflow-y: hidden;
      transition: max-height 0.75s ease-in-out;
      flex-direction: row;
      display: block;
      margin-top: 10px;
      margin-bottom: 20px;
      color: #333333;
      overflow-x: hidden;
      font-size: 14px;
      max-height: 0;

      & * {
        font-size: 14px !important;
      }
    }

    &_open {
      font-family: "$rxc-rayban-lato-font-Regular";
      height: 100%;
      overflow-y: hidden;
      transition: max-height 0.75s ease-in-out;
      flex-direction: row;
      display: block;

      margin-top: 10px;
      margin-bottom: 20px;
      color: #333333;
      overflow-x: hidden;
      font-size: 14px;

      & * {
        font-size: 14px !important;
      }
    }

    &__title {
      font: 14px "$rxc-rayban-lato-font-Regular", Verdana, Arial, sans-serif;
      font-weight: 600;
    }

    &__form {
      height: 100%;
      display: flex;
      flex-direction: column;

      &_table {
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #1f1f24;

        @include rxc-desktop-only {
          display: flex;

          >div {
            flex-wrap: nowrap !important;
          }
        }

        @include rxc-small-desktop-only {
          display: flex;
          flex-flow: column wrap;
          flex: 0 0 100%;
        }
      }

      &_leftcol {
        @include rxc-desktop-only {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 24px 15px 9px 0px;
          flex-direction: column;
        }

        @include rxc-mobile-only {
          display: flex;
          flex-direction: row;
          //justify-content: space-evenly;
          // margin: 12px 15px 15px 105px;
          margin: 12px 15px 15px 76px;
          flex: 0 0 100%;
          align-self: flex-start;
        }

        @include rxc-medium-mobile-only {
          margin: 12px 15px 15px 77px;
        }

        @media screen and (min-width: 375px) and (max-width: 414px) {
          margin: 12px 15px 15px 77px;
        }
      }

      &_leftlabel {
        font: 14px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;

        @include rxc-mobile-only {
          display: flex;
          align-items: center;
          justify-content: center;

          & div {
            margin-right: 3px;
          }
        }

        &:first-child {
          @include rxc-mobile-only {
            margin-right: 61px;
          }

          @media screen and (min-width: 375px) and (max-width: 414px) {
            margin-right: 43px;
          }

          @media screen and (min-width: 320px) and (max-width: 360px) {
            margin-right: 8px;
          }
        }
      }

      &_col {
        @include rxc-desktop-only {
          flex-flow: column nowrap;
          display: flex;
          justify-content: center;
          margin-right: 16px;
        }

        @include rxc-mobile-only {
          display: flex;
          //flex-wrap: wrap;
          flex-direction: row;
          flex: 0 0 100%;
          // justify-content: space-evenly;
          // margin: 24px 15px 9px 0px;
        }

        &.invisible {
          visibility: hidden;

          @include rxc-mobile-only {
            display: none;
          }
        }

        &.maxWidthWithoutADD {
          @include rxc-desktop-only {
            max-width: calc(33.33% - 20px);
          }
        }

        &.maxWidthIsADD {
          @include rxc-desktop-only {
            max-width: calc(25% - 20px);
          }
        }
      }

      &_row {
        margin-bottom: 15px;
        white-space: nowrap;

        // @include rxc-mobile-only {
        //   &:first-child{
        //     width: 60px;
        //     margin-right: 16px;
        //   }
        //   width: 127px;
        //   margin-right: 20px;
        //   white-space: normal;
        // }
        // @include rxc-medium-mobile-only{
        //   width: 100px;
        // }
        @include rxc-mobile-only {
          margin-bottom: 16px;
          width: 127px;
          margin-right: 26px;

          &:first-child {
            width: 60px;
            margin-right: 16px;
            display: flex;
            align-items: center;
          }

          white-space: normal;
        }

        @media screen and (min-width: 375px) and (max-width: 414px) {
          margin-right: 15px;
          width: 120px;

          &:first-child {
            width: 59px;
            display: flex;
            align-items: center;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 360px) {
          width: 90px;
          margin-right: 10px;
          margin-bottom: 16px;
          white-space: normal;
        }
      }

      &_boldsr {
        margin-right: 5px;
        font: 14px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
      }

      &_select {
        direction: ltr;
        text-align-last: left;

        &_custom {
          width: 135px;
          border-radius: 4px;
          border: solid 1px #666;

          @media screen and (min-width: 375px) and (max-width: 414px) {
            width: 120px;
          }

          @media screen and (min-width: 320px) and (max-width: 360px) {
            width: 90px;
          }
        }

        & select {
          width: 133px;
          height: 48px;
          display: flex;
          justify-content: start;
          padding-left: 10px;
          padding-bottom: 2px;
          background-color: #ffffff;
          border-radius: 4px;
          border: solid 1px #666;
          color: #333333;
          //border-radius: 5px;
          background-image: url("data:image/svg+xml,%3csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 13.602.195 5.797l.943-.943L8 11.716l6.862-6.862.943.943L8 13.602z' fill='black'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: 105px center;
          -moz-appearance: none;
          -webkit-appearance: none;
          -ms-appearance: none;

          @media screen and (min-width: 375px) and (max-width: 414px) {
            width: 120px;
            background-position: 93px center;
          }

          @media screen and (min-width: 320px) and (max-width: 360px) {
            width: 90px;
            background-position: 63px center;
          }

          /* width */
          &::-webkit-scrollbar {
            width: 4px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #d8d8d8;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #767676;
            border-radius: 8px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #767676;
          }
        }

        &_prescription {
          & input {
            background-color: white;
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: normal;
          }
        }

        & #right_eye_axis,
        & #left_eye_axis {
          border-radius: 4px;
          border: solid 1px #666;
        }

        @include rxc-desktop-only {
          & #right_eye_axis {
            width: 133px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
          }

          & #right_eye_axis:disabled {
            width: 133px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
            border: solid 1px #e2e2e5;
            background-color: #ffffff;
          }

          & #left_eye_axis {
            width: 133px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
          }

          & #left_eye_axis:disabled {
            width: 133px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
            border: solid 1px #e2e2e5;
            background-color: #ffffff;
          }
        }

        @include rxc-mobile-only {
          & #right_eye_axis {
            width: 135px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
          }

          & #right_eye_axis:disabled {
            width: 135px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
            background-color: #ffffff;
            border: solid 1px #e2e2e5;
          }

          & #left_eye_axis {
            width: 135px;
            height: 48px;
            // border-radius: 5px;
            padding-left: 10px;
          }

          & #left_eye_axis:disabled {
            width: 135px;
            height: 48px;
            //border-radius: 5px;
            padding-left: 10px;
            border: solid 1px #e2e2e5;
            background-color: #ffffff;
          }
        }

        @media screen and (min-width: 375px) and (max-width: 414px) {

          & #right_eye_axis,
          #right_eye_axis:disabled,
          #left_eye_axis,
          #left_eye_axis:disabled {
            width: 120px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 360px) {

          & #right_eye_axis,
          #right_eye_axis:disabled,
          #left_eye_axis,
          #left_eye_axis:disabled {
            width: 90px;
          }
        }
      }
    }

    &__warning {
      width: 365px;
      font: 12px "$rxc-rayban-lato-font-Regular", Verdana, Arial, sans-serif !important;
      line-height: 1.23;
      color: #192c50;
      font-size: 12px !important;
    }
  }

  .IssueDateInput {
    width: 50%;
    max-width: 304px;
    margin-bottom: 20px;

    label {
      display: block;
      position: relative;

      .input {
        position: relative;
      }

      .input__label {
        position: absolute;
        font-size: 14px;
        background-color: white;
        top: -10px;
        left: 11px;
        padding: 0 4px;
        font-family: "Lato";
        font-weight: 400;

        span.orange {
          color: #e1523e;
          font-weight: 600;
        }
      }

      input {
        width: 100%;
        color: #666666;
        padding: 12px 16px;
        font-size: 16px;
        line-height: 150%;
        border: 1px solid #cccccc;
        border-radius: 8px;
        font-family: "Lato";
        font-weight: 400;

        &.error {
          border: 3px solid #e1523e;
        }
      }
    }

    .SimpleTextError__message {
      padding-left: 16px;

      @include rxc-mobile-only {
        min-width: 190px;
        padding-left: 1px;
      }
    }
  }

  &__clearButtonError {
    font-family: "$rxc-rayban-lato-font-Regular";
    margin-right: 35px;
    margin-top: 20px;
    float: right;
    margin: 0;
    font-size: 12px !important;
    height: 24px;
    padding: 0px 22px;
  }

  .IncompatibleFrame,
  .IncompatibleLenses {
    border: 1px solid #000;
    padding: 16px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    height: 100%;
    margin: 8px 0px;
  }

  .IncompatibleFrame {
    @include rxc-desktop-only {
      grid-template-areas:
        "icon text"
        "icon button";
    }

    svg {
      display: none;
    }

    &__text {
      grid-area: text;
      color: var(--primay-red-primary-red-error, #d51c29);
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      line-height: 21px;
      text-align: center;

      a {
        font-weight: 700;
        text-decoration-line: underline;

        &:visited {
          color: black;
        }
      }
    }

    &__button {
      display: flex;
      background: var(--neutral-black, #000);
      border-radius: 4px;
      color: #ffffff;
      padding: 9px 24px;
      cursor: pointer;
      grid-area: button;
      text-align: center;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 177px;
      height: 40px;
      color: var(--neutral-white, #fff);
      font-family: "Lato";
      font-size: 16px;
      font-style: normal;
      //line-height: 24px;

      @include rxc-desktop-only {
        max-width: max-content;
      }
    }
  }
}

.IncompatibleFrame,
.IncompatibleLenses {
  background: #fff;
  color: #222222;
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 16px;
  text-align: left;
  border-radius: 8px;
  border: 1px solid #e32118;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lato";

  &__text {
    width: 100%;
    text-align: center;

    a {
      text-decoration: underline;
      font-weight: 700;

      &:visited {
        color: black;
      }
    }
  }

  &__button {
    display: inline-block;
    background: #222;
    font-family: "Oswald";
    text-transform: uppercase;
    color: #ffffff;
    padding: 8px 24px;
    margin-top: 16px;
    cursor: pointer;
  }
}

.MoreOptions {
  // &__formWrapper {
  //   margin-top: 24px;
  // }

  &__accordionBtnContainer {
    font-family: Lato;

    margin-top: 33px;

    @include rxc-mobile-only {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0px;
    }

    &__accordionBtnClosed {
      font: 15px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
      text-decoration: underline;
      border: none;
      background: none;
      color: #000;
      padding-bottom: 20px;
      cursor: pointer;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        margin-left: 6px;
        margin-top: 5px;
        border: solid #000;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0px;
        position: relative;
        top: -4px;
      }
    }

    &__accordionBtnOpen {
      font: 15px $rxc-rayban-lato-font, Verdana, Arial, sans-serif;
      text-decoration: underline;
      border: none;
      background: none;
      color: #000;
      cursor: pointer;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        transform: rotate(-135deg);
        margin-left: 6px;
        margin-top: 5px;
        border: solid #000;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 0px;
        position: relative;
        top: 1px;
      }
    }
  }

  &__checkboxContainer {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;

    &_noMoreOptions {
      >div:first-child {
        display: flex;
        align-items: center;
        margin: 24px 0px 16px;
      }
    }
  }

  &__checkBoxText {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-left: 16px;
    color: #1f1f24;
    align-self: center;

    @include rxc-desktop-only {
      min-width: 200px;
    }
  }

  &__checkBoxPrismPrice {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    margin-left: 5px;
  }

  &__commentContainer {
    textarea {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.CustomCheckbox {
  &__checkbox {
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    flex-grow: 0;
    border-radius: 1px;
    border: solid 1px #666;
    background-color: #fff;
    display: inline-block;
    position: relative;
    min-width: 22px !important;

    &.marginTop {
      margin-top: 4px;
    }

    &.checked {
      background: black;

      &:after {
        content: "\2714";
        font-size: 11px;
        position: absolute;
        top: 5px !important;
        left: 6px !important;
        color: white;
      }
    }

    &:hover {
      border: 1px solid rgb(90, 90, 90);
    }
  }
}

.AgreementCheckbox {
  &__container {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 24px;

    &.invisible {
      display: none;
    }
  }

  &__text {
    font-family: "Lato";
    font-size: 14px;
    margin-left: 10px;
    font-weight: 400;
    line-height: 160%;
    color: #1F1F24;

    @include rxc-desktop-only {
      max-width: 92%;
    }
  }

  &__tooltip {
    display: inline-block;
    position: relative;
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-left: 8px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16V12' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 8H12.01' stroke='%23000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &:hover {
      cursor: pointer;

      .AgreementCheckbox__tooltip__body {
        display: block;
      }
    }

    @include rxc-mobile-only {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: -3px;
    }

    @include rxc-desktop-only {
      margin-top: 4px;
    }

    &__body {
      display: none;
      position: absolute;
      left: -180px;
      background: #f7f6f4;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 8px !important;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      // outline: 1px solid #e5e5e5;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      padding: 16px;
      line-height: 150%;
      bottom: 26px;

      &.visible {
        display: flex;
      }

      @include rxc-desktop-only {
        width: 375px;
      }

      @include rxc-mobile-only {
        top: unset;
        left: -152px;
        width: 59vw;
      }

      @media only screen and (min-width: 390px) {
        left: -88px;
      }

      &::after {
        content: "";
        position: absolute;
        width: 29px;
        height: 18px;
        bottom: -16px;
        left: 46%;
        z-index: 100;
        background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%23f7f6f4'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        @include rxc-mobile-only {
          left: 145px;
        }

        @media only screen and (min-width: 390px) {
          left: 80px;
        }
      }
    }
  }
}

.IncompatibleError {
  &__container {
    font-family: "$rxc-rayban-lato-font-Regular";
    display: inline-block;
    vertical-align: middle;
    border: 1px solid red;
    border-radius: 8px;
    padding: 30px 30px 30px 30px;
    align-items: center !important;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-bottom: 24px;
    margin-top: 8px;

    @include rxc-desktop-only {
      min-width: 50%;
      max-width: 80%;
    }

    @include rxc-mobile-only {
      min-width: 70%;
      max-width: 100%;
    }

    &__firstline {
      color: #ff0000;
      margin-bottom: 10px;
      text-align: center;
    }

    &__alternative {
      margin-bottom: 10px;
      text-align: center;
    }

    &__shopCompatibleBtn {
      width: auto;
      height: 40px;
      border: none;
      border-radius: 18px !important;
      padding: 0px 10px 0px 12px;
      font-size: 11px !important;
      background-color: $rxc-black-rayban !important;
      color: #ffffff !important;
      font-size: 14px;
      text-transform: uppercase;

      @include rxc-mobile-only {
        width: 100%;
        justify-content: center;
        margin-top: 0px;
      }
    }

    &__clearAll {
      height: 40px;
      border-radius: 100px;
      background-color: white;
      color: $rxc-black-rayban;
      text-transform: uppercase;
      font-family: $rxc-rayban-lato-font;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      padding: 0 32px;
      align-self: center;
      border: solid 1px $rxc-black-rayban;
      letter-spacing: 0.5px;
      width: 100%;
    }
  }
}

.IncompatibleLensType {
  position: relative;
  padding: 13px 46px 7px 32px;
  background: #f2f4f5;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222222;

  &__message {
    &::before {
      content: "";
      position: absolute;
      top: 13px;
      left: 8px;
      height: 16px;
      width: 16px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15.3333C3.95633 15.3333 0.666667 12.0437 0.666667 8C0.666667 3.95633 3.95633 0.666667 8 0.666667C12.0437 0.666667 15.3333 3.95633 15.3333 8C15.3333 12.0437 12.0437 15.3333 8 15.3333Z' fill='black'/%3e%3cpath d='M8.33333 6H7.66667V12H8.33333V6Z' fill='black'/%3e%3cpath d='M8.33333 4H7.66667V4.66667H8.33333V4Z' fill='black'/%3e%3c/svg%3e");
    }
  }
}

.SimpleTextError {
  &__message {
    color: #b50900;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    font-family: Lato;
  }
}

.StepContainer__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;

  @include rxc-mobile-only {
    margin-right: 0;
  }
}