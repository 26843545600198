.TogglePrescription {
  &__container {

    input {
      position: absolute;
      left: 0;
      width: 1px;
      clip: rect(1px, 1px, 1px, 1px);
      margin: 0 0 0 0%;
      padding: 0 0 0 0%;
      border: 0 !important;
      overflow: hidden;
    }

    label {
      position: relative;
      background-color: #F2F2F2;
      color: #1F1F24;
      font-family: var(--font-primary);
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      padding: 9px 31px;
      border-radius: 20px;
      display: flex;
      max-width: max-content;
      gap: 4rem;
      cursor: pointer;

      &::before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 55%;
        height: 100%;
        background-color: #1F1F24;
        border-radius: 20px;
        transition: left 0.2s ease-in-out;
      }

      span {
        position: relative;
        z-index: 10;

      }

      .non-prescription {
        color: #fff;
      }
    }

    input:checked+label {
      &::before {
        left: 50%;
      }

      .non-prescription {
        color: #1F1F24;
      }

      .prescription {
        color: #fff;
      }
    }
  }
}