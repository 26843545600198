@import "../../../mixins/global.scss";

:root {
  --font-primary: "Oswald";
  --font-secondary: "Lato";
}

.ProductContainer {
  &__productImageContainer {
    &__productImageContent {
      .LensPreviewFallback__btn_container__detailBox {
        @include rxc-mobile-only {
          display: none;
        }
      }

      &__productImage {
        @include rxc-mobile-only {
          margin-bottom: 0;
        }
      }

      .lens-slider {
        @include rxc-mobile-only {
          width: unset !important;
          max-width: 270px;
          min-width: 150px;
        }
      }
    }
  }

  &__container {
    padding: 0;
  }

  &__topBrandContainer {
    @include rxc-mobile-only {
      display: none;
    }
  }
}

.ScrollView {
  &__scrollbar {
    @include rxc-mobile-only {
      overflow: unset !important;
      background-color: #fff;
    }
  }
}

.StepContainer {
  &__container {
    padding-bottom: 50px !important;
    min-height: 100%;
    height: unset;

    @include rxc-mobile-only {
      padding-bottom: 100px;
    }

    .divider {
      background-color: #e2e2e5;
      min-height: 1px;
    }
  }
}

.FrameSection,
.LensesSection {
  &__container {
    &__title {
      font-family: var(--font-primary);
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.skeleton {
  .selected {
    border: 1px solid #e2e2e5 !important;
  }

  .MainHeader__center {
    position: relative;

    &::before {
      @include skeleton-bg();
      background: #fff;
    }
  }

  .FrameSection,
  .LensesSection {
    &__container {
      &__title {
        position: relative;

        &::before {
          @include skeleton-bg();
          border-radius: 8px;
          width: 101%;
          left: -1px;
        }

        @include skeleton-animation();
      }
    }
  }

  .LensesSection {
    &__chip {
      position: relative;

      &::before {
        @include skeleton-bg();
        top: -1px;
        left: -1px;
        width: 102%;
        height: 104%;
        border-radius: 40px;
      }

      @include skeleton-animation();
    }
  }

  .TogglePrescription {
    &__container {
      label {
        position: relative;

        &::before {
          @include skeleton-bg();
          border-radius: 50px;
          z-index: 99999;
          left: 0 !important;
          transition: none;
        }

        @include skeleton-animation();
      }
    }
  }

  .LensCard {
    &__container {
      position: relative;

      &::before {
        @include skeleton-bg();
        z-index: 99999;
        width: 101%;
        height: 101%;
        top: -1px;
        left: -1px;
        border-radius: 8px;
      }

      @include skeleton-animation();
    }
  }

  .BottomBarTotalPrice {
    &__pricerow {

      &__label,
      &__price {
        position: relative;
        overflow: hidden;

        &::before {
          @include skeleton-bg();
          border-radius: 50px;
          width: 101%;
          left: -1px;
        }

        @include skeleton-animation();
      }
    }
  }

  .Loader {
    &__container {
      min-height: 150px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.mixAndMatch {
  .StepTitle {
    &__container {
      padding-top: 24px;

      &__title {
        font-size: 24px;
        font-weight: 700;
      }

      &__titleMobile {
        display: none !important;
      }

      &__subtitle {
        @include rxc-mobile-only {
          display: none;
        }
      }
    }
  }

  .ProductContainer {
    &__container {
      display: block !important;

      .ProductContainer__productImageContainer__productImageContent {
        max-width: 570px;

        &__productImage {
          mix-blend-mode: multiply;
        }
      }

      @include rxc-mobile-only {
        padding: 24px 0;
      }

      .LensPreviewTransaction {
        &__container {
          @include rxc-mobile-only {
            img {
              max-width: 300px;
            }

            .LensPreviewTransaction__white_img,
            .LensPreviewTransaction__overlay {
              max-width: 100px !important;
            }

          }
        }

        &_range {
          &__container {
            @include rxc-mobile-only {
              //display: none;
              max-width: 380px;
            }
          }
        }
      }
    }
  }

  .ScrollView {
    &__scrollbar {
      background-color: #fff !important;
    }
  }
}